import styled from 'styled-components';

export const BlockContainer = styled.div`
  display: flex;
  padding: 93px 0 51px 0;
  width: 100%;
  height: 925px;
  flex-direction: column;

  .react-multi-carousel-list {
    margin-top: 122px;
    height: 180px;
    width: 100%;

    ul {
      display: flex;
      width: 100%;
      height: 100%;
      gap: 12px;
    }
  }
`;

export const TextBlock = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 693px;
  height: 396px;
`;

export const TextTitle = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: 0.16em;
  text-transform: uppercase;
`;

export const TextDescr = styled.div`
  display: flex;
  margin-top: 36px;
  font-weight: 400;
  font-size: 12px;
  line-height: 180%;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  max-width: 500px;
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 180px;
  margin-top: 122px;
  gap: 12px;
  justify-content: space-between;
`;

export const Panel = styled.div`
  cursor: pointer;
  flex: 0.5;
  height: 56px;
  width: 100%;
  position: relative;
  text-align: center; /* Center text for inactive panels */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  background: #8080801a; /* Hex for grey with 10% opacity */
  color: #fff;
  padding: 10px;
  transition:
    flex 0.1s ease,
    height 0.1s ease,
    opacity 0.1s ease,
    max-width 0.1s ease,
    color 0.1s ease,
    background-color 0.1s ease;

  &.active {
    display: flex;
    flex-direction: column;
    flex: 5;
    max-width: 605px;
    padding: 34px;
    height: 100%;
    background: linear-gradient(90deg, #c7acff 0%, #ffffff 100%);
    clip-path: polygon(0 0, calc(100% - 25px) 0, 100% 25px, 100% 100%, 0% 100%);
    opacity: 1;
    color: #000;
    text-align: left;

    h4 {
      height: 100%;
      align-self: flex-start;
      font-size: 22px;
      margin: 0;
      color: inherit;
      text-align: left;
    }

    p {
      margin-top: 30px;
      display: block;
    }
  }

  h4 {
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    transition:
      font-size 0.4s ease,
      color 0.4s ease;
  }

  p {
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 10px;
    color: inherit;
    text-align: start;
    line-height: 1.4;
    display: none;
  }

  @media (max-width: 400px) {
    flex: 1;
    height: 80px;
    &:nth-of-type(4) {
      display: none;
    }
  }
`;
export const ArrowsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  margin-top: 55px;

  button {
    font-size: 1.5rem;
    cursor: pointer;
    color: #fff;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #fff;
    padding: 11px 16px;
    margin: 0 3px;
    background: rgba(74, 60, 132, 0.1);
    border: 0.5px solid rgba(127, 100, 242, 0.25);
  }
`;
