// ToastProvider.tsx
import React from 'react';
import { toast, cssTransition, ToastContainer } from 'react-toastify';
import ToastContext from './ToastContext';
import { ToastProps } from './toast.types';
import {
  SuccessIcon,
  ErrorIcon,
  WarnIcon,
  ToastIcon,
  SoonIcon,
} from 'shared/icon';
import { StyledToast, CloseButton } from './Toast.styled';

const bounce = cssTransition({
  enter: 'animate__animated animate__bounceIn',
  exit: 'animate__animated animate__bounceOut',
});

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const showToast: ToastProps['showToast'] = (message, type, options) => {
    const toastOptions = {
      autoClose: 2000,
      hideProgressBar: true,
      theme: 'light',
      closeOnClick: true,
      transition: bounce,
      closeButton: (
        <CloseButton>
          <ToastIcon />
        </CloseButton>
      ),
      icon:
        type === 'success' ? (
          <SuccessIcon />
        ) : type === 'error' ? (
          <ErrorIcon />
        ) : type === 'warn' ? (
          <WarnIcon />
        ) : type === 'soon' ? (
          <SoonIcon />
        ) : undefined,
      ...options,
    };

    switch (type) {
      case 'success':
        toast.success(message, toastOptions);
        break;
      case 'error':
        toast.error(message, toastOptions);
        break;
      case 'warn':
        toast.warn(message, toastOptions);
        break;
      case 'soon':
        toast.info(message, {
          ...toastOptions,
          className: 'Toastify__toast--soon',
        });
        break;
      default:
        toast(message, toastOptions);
    }
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <StyledToast>
        <ToastContainer />
      </StyledToast>
    </ToastContext.Provider>
  );
};
