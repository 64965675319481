import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { ROUTES } from 'app';

import { useAuth } from '../authProvoider/useAuth';

interface ProtectedRouteProps {
  children: ReactNode;
}

// const isDev = true;
// const isDev = false;
const isDev = process.env.NODE_ENV === 'development';

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  // Если пользователь не авторизован, перенаправляем на страницу входа
  if (!isDev && !isAuthenticated) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  // Если пользователь авторизован, возвращаем защищенные дочерние компоненты
  return <>{children}</>;
};
