import styled from 'styled-components';

export const Text = styled.div`
  font-family: Titillium Web;
  color: var(--white-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-transform: uppercase;

  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const PageTitle = styled.div`
  font-family: Titillium Web;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 3.2px;
  text-transform: uppercase;
  text-align: center;
  color: var(--white-color);
  width: 100%;
`;
