import React, { useState } from 'react';
import {
  StyledInputWrapper,
  StyledInputField,
  StyledIconContainer,
} from './Input.styled';
import { InputProps } from './input.types';

export const Input: React.FC<InputProps> = ({
  value,
  color,
  bgColor,
  placeholderColor,
  onChange,
  placeholder,
  type,
  icon: Icon,
  disabled = false,
  onSubmit,
}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <StyledInputWrapper
      isActive={isActive || Boolean(value)}
      disabled={disabled}
      bgColor={bgColor}
    >
      <StyledInputField
        type={type}
        color={color}
        value={value || ''}
        onChange={(e) => onChange?.(e.target.value)}
        placeholder={placeholder}
        placeholderColor={placeholderColor}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        disabled={disabled}
      />
      {Icon && (
        <StyledIconContainer
          isActive={isActive}
          disabled={disabled}
          onClick={() => !disabled && onSubmit?.(value)}
        >
          <Icon />
        </StyledIconContainer>
      )}
    </StyledInputWrapper>
  );
};
