export const cardsData = [
  {
    id: 1,
    title: 'AI Avatar',
    descr:
      'Create a unique, intelligent digital persona that learns and evolves with your interactions and tasks',
  },
  {
    id: 2,
    title: 'Earn & Learn',
    descr:
      'Monetize your AI training skills and earn rewards while developing cutting-edge artificial intelligence',
  },
  {
    id: 3,
    title: 'Cross-Platform',
    descr:
      "Build a unified digital identity that transcends metaverse boundaries, carrying your AI's knowledge everywhere",
  },
  {
    id: 4,
    title: 'Community',
    descr:
      'Join a collaborative ecosystem of innovators, sharing knowledge and pushing the boundaries of AI development',
  },
];
