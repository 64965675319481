export const generateShareURL = (link: string, text: string): string => {
  const encodedLink = encodeURIComponent(link);
  const encodedText = encodeURIComponent(text);
  return `https://t.me/share/url?url=${encodedLink}&text=${encodedText}`;
};

export const generateReferralLink = (id: number): string => {
  return `https://t.me/sint_dev_bot?start=${id}`;
};

export const getVisibleReferralLink = (link: string): string => {
  const url = new URL(link);
  return `${url.host}${url.pathname}`;
};
