import styled, { keyframes } from 'styled-components';
import gifImage from 'shared/icon/gif/bg.gif';

// Анимации
const animateBg = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`;

const animateSpan = keyframes`
  0% {
    transform: rotate(315deg) translateX(0);
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: rotate(315deg) translateX(-1000px);
    opacity: 0;
  }
`;

// Стили для Background
export const Background = styled.div`
  background: var(--gradient);
  overflow-x: hidden;
  overflow-y: hidden;
  height: calc(100vh - 72px);
  position: relative;
  /* background-image: url(${gifImage}); */
`;

// Стили для Layout
export const StyledLayout = styled.div`
  padding: 15px;
  z-index: 2;
  overflow: scroll;
  position: absolute;
  width: 100vw;
  height: calc(100vh - 76px);
`;

// Секция
export const Section = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-size: cover;
  animation: ${animateBg} 30s linear infinite;
`;

// Спаны
export const Span = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
  box-shadow:
    0 0 0 4px rgba(255, 255, 255, 0.1),
    0 0 0 8px rgba(255, 255, 255, 0.1),
    0 0 20px rgba(255, 255, 255, 0.1);
  animation: ${animateSpan} 3s linear infinite;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 300px;
    height: 1px;
    background: linear-gradient(90deg, #fff, transparent);
  }

  &:nth-child(1) {
    top: 0;
    right: 0;
    left: initial;
    animation-delay: 0s;
    animation-duration: 1s;
  }

  &:nth-child(2) {
    top: 0;
    right: 80px;
    left: initial;
    animation-delay: 0.2s;
    animation-duration: 3s;
  }

  &:nth-child(3) {
    top: 80;
    right: 0px;
    left: initial;
    animation-delay: 0.4s;
    animation-duration: 2s;
  }

  &:nth-child(4) {
    top: 0;
    right: 180px;
    left: initial;
    animation-delay: 0.6s;
    animation-duration: 1.5s;
  }

  &:nth-child(5) {
    top: 0;
    right: 400px;
    left: initial;
    animation-delay: 0.8s;
    animation-duration: 2.5s;
  }

  &:nth-child(6) {
    top: 0;
    right: 600px;
    left: initial;
    animation-delay: 1s;
    animation-duration: 3s;
  }

  &:nth-child(7) {
    top: 300px;
    right: 0px;
    left: initial;
    animation-delay: 1.2s;
    animation-duration: 1.75s;
  }

  &:nth-child(8) {
    top: 0px;
    right: 700px;
    left: initial;
    animation-delay: 1.4s;
    animation-duration: 1.25s;
  }

  &:nth-child(9) {
    top: 0px;
    right: 1000px;
    left: initial;
    animation-delay: 0.75s;
    animation-duration: 2.25s;
  }

  &:nth-child(10) {
    top: 0px;
    right: 450px;
    left: initial;
    animation-delay: 2.75s;
    animation-duration: 2.75s;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space-between;
`;

export const H100 = styled.div`
  height: 100%;
  position: relative;
`;

export const CentredWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
