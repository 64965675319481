import * as React from 'react';
import { IconProps } from '../icon.types';

export const LotusIcon: React.FC<IconProps> = ({
  color = '#fff',
  width,
  height,
  size = 24,
  ...props
}) => (
  <svg
    width={size || width}
    height={size || height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12 1.75C17.6519 1.75 22.25 6.34813 22.25 12C22.25 17.6519 17.6519 22.25 12 22.25C6.34813 22.25 1.75 17.6519 1.75 12C1.75 6.34813 6.34813 1.75 12 1.75ZM12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM11.9994 9.66711C9.72656 9.66711 7.68982 10.2963 6.31451 11.29C5.81024 10.7549 5.58032 10.1908 5.58032 9.66644C5.58032 8.88281 6.09381 8.01025 7.26929 7.27887C8.42944 6.55695 10.0994 6.07532 12.0006 6.07532C13.9017 6.07532 15.5717 6.55695 16.7319 7.27887C17.9073 8.01025 18.4208 8.883 18.4208 9.66663L19.5 9.66644C19.5 7.08923 16.1424 5 12.0006 5C7.85876 5 4.50116 7.08923 4.50116 9.66644C4.50116 12.2436 7.85876 14.3329 12.0006 14.3329C14.2735 14.3329 16.3102 13.7037 17.6855 12.71C18.1898 13.2451 18.4197 13.8092 18.4197 14.3336C18.4197 15.1172 17.9062 15.9897 16.7307 16.7212C15.5706 17.443 13.9006 17.9247 11.9994 17.9247C10.0983 17.9247 8.42828 17.443 7.26813 16.7212C6.09265 15.9897 5.57916 15.117 5.57916 14.3334L4.5 14.3336C4.5 16.9108 7.8576 19 11.9994 19C16.1413 19 19.4989 16.9108 19.4989 14.3336C19.4989 11.7564 16.1413 9.66711 11.9994 9.66711ZM16.7319 12.054C15.5717 12.7759 13.9017 13.2576 12.0006 13.2576C10.0994 13.2576 8.42944 12.7759 7.26929 12.054C7.2403 12.036 7.21167 12.0178 7.18347 11.9996C7.2113 11.9817 7.2395 11.9638 7.26813 11.946C8.42828 11.2241 10.0983 10.7424 11.9994 10.7424C13.9006 10.7424 15.5706 11.2241 16.7307 11.946C16.7597 11.964 16.7883 11.9822 16.8165 12.0004C16.7887 12.0183 16.7605 12.0362 16.7319 12.054Z'
      fill={color}
    />
  </svg>
);
