import { Balance } from 'feature/balance';
import { MainGame } from 'feature/game';
import { Level } from 'feature/level';
import { H100, Header } from 'shared/layout';

export const Game = () => {
  return (
    <H100>
      <Header>
        <Level />
        <Balance />
      </Header>
      <MainGame />
    </H100>
  );
};
