import { LandingContent } from './components';
import GlobalStyle from './GlobalStyles';

export const Landing = () => {
  return (
    <>
      <GlobalStyle />
      <LandingContent />
    </>
  );
};
