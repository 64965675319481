import * as React from 'react';
import { IconProps } from './icon.types';
import { colors } from 'app/colors';

export const LogoIcon: React.FC<IconProps> = ({
  color = colors.whiteColor,
  width = 20,
  height = 20,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16.7307 11.946C16.7597 11.964 16.7883 11.9822 16.8165 12.0004C16.7887 12.0183 16.7605 12.0362 16.7319 12.054C15.5717 12.7759 13.9017 13.2576 12.0006 13.2576C10.0994 13.2576 8.42944 12.7759 7.26929 12.054C7.24028 12.036 7.21168 12.0178 7.18347 11.9996C7.21131 11.9817 7.23953 11.9638 7.26815 11.946C8.4283 11.2241 10.0983 10.7424 11.9994 10.7424C13.9006 10.7424 15.5706 11.2241 16.7307 11.946Z'
      fill='white'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM18.4197 14.3336C18.4197 13.8092 18.1898 13.2451 17.6855 12.71C16.3102 13.7037 14.2735 14.3329 12.0006 14.3329C7.85875 14.3329 4.50114 12.2436 4.50114 9.66644C4.50114 7.08924 7.85875 5 12.0006 5C16.1424 5 19.5 7.08924 19.5 9.66644L18.4208 9.66664C18.4208 8.88302 17.9073 8.01027 16.7319 7.27884C15.5717 6.55695 13.9017 6.07531 12.0006 6.07531C10.0994 6.07531 8.42944 6.55695 7.26929 7.27884C6.0938 8.01027 5.58031 8.88282 5.58031 9.66644C5.58031 10.1908 5.81022 10.7549 6.31451 11.29C7.68981 10.2963 9.72654 9.66712 11.9994 9.66712C16.1413 9.66712 19.4989 11.7564 19.4989 14.3336C19.4989 16.9108 16.1413 19 11.9994 19C7.85761 19 4.5 16.9108 4.5 14.3336L5.57917 14.3334C5.57917 15.117 6.09267 15.9897 7.26815 16.7212C8.4283 17.443 10.0983 17.9247 11.9994 17.9247C13.9006 17.9247 15.5706 17.443 16.7307 16.7212C17.9062 15.9897 18.4197 15.1172 18.4197 14.3336Z'
      fill={color}
    />
  </svg>
);
