import React from 'react';
import { IconProps } from '../icon.types';

export const SuccessIcon: React.FC<IconProps> = ({
  color = '#271F4E',
  width = 24,
  height = 24,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12 2C6.47717 2 2 6.47717 2 12C2 17.5228 6.47717 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47717 17.5228 2 12 2ZM17.0303 9.53027L11.0303 15.5303C10.8838 15.6768 10.6919 15.75 10.5 15.75C10.3081 15.75 10.1162 15.6768 9.96973 15.5303L6.96973 12.5303C6.67676 12.2373 6.67676 11.7627 6.96973 11.4697C7.2627 11.1768 7.7373 11.1768 8.03027 11.4697L10.5 13.9395L15.9697 8.46973C16.2627 8.17676 16.7373 8.17676 17.0303 8.46973C17.3232 8.7627 17.3232 9.2373 17.0303 9.53027Z'
      fill={color}
    />
  </svg>
);

export const ErrorIcon: React.FC<IconProps> = ({
  color = '#C80000',
  width = 24,
  height = 24,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M2 12C2 6.47717 6.47717 2 12 2C17.5228 2 22 6.47717 22 12C22 17.5228 17.5228 22 12 22C6.47717 22 2 17.5228 2 12ZM7.75873 8.81842C7.46584 8.52552 7.46584 8.05065 7.75873 7.75776C8.05163 7.46486 8.5265 7.46486 8.81939 7.75776L12.0014 10.9397L15.1834 7.75776C15.4762 7.46486 15.9511 7.46486 16.244 7.75776C16.5369 8.05065 16.5369 8.52552 16.244 8.81842L13.062 12.0004L16.244 15.1824C16.5369 15.4753 16.5369 15.9501 16.244 16.243C15.9511 16.5359 15.4762 16.5359 15.1834 16.243L12.0014 13.0611L8.81939 16.243C8.5265 16.5359 8.05163 16.5359 7.75873 16.243C7.46584 15.9501 7.46584 15.4753 7.75873 15.1824L10.9407 12.0004L7.75873 8.81842Z'
      fill={color}
    />
  </svg>
);

export const WarnIcon: React.FC<IconProps> = ({
  color = '#FFA300',
  width = 24,
  height = 24,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M12 2C6.47717 2 2 6.47717 2 12C2 17.5228 6.47717 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47717 17.5228 2 12 2ZM11.25 6.75C11.25 6.33579 11.5858 6 12 6C12.4142 6 12.75 6.33579 12.75 6.75V14.25C12.75 14.6642 12.4142 15 12 15C11.5858 15 11.25 14.6642 11.25 14.25V6.75ZM11.25 17.25C11.25 16.8358 11.5858 16.5 12 16.5C12.4142 16.5 12.75 16.8358 12.75 17.25C12.75 17.6642 12.4142 18 12 18C11.5858 18 11.25 17.6642 11.25 17.25Z'
      fill={color}
    />
  </svg>
);

export const SoonIcon: React.FC<IconProps> = ({
  color = '#271F4E',
  width = 24,
  height = 24,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.0013 2.16699C7.01824 2.16699 2.16797 7.01726 2.16797 13.0003C2.16797 18.9834 7.01824 23.8337 13.0013 23.8337C18.9844 23.8337 23.8346 18.9834 23.8346 13.0003C23.8346 7.01726 18.9844 2.16699 13.0013 2.16699ZM13.0013 23.0212C7.4758 23.0212 2.98047 18.5258 2.98047 13.0003C2.98047 7.47482 7.4758 2.97949 13.0013 2.97949C18.5268 2.97949 23.0221 7.47482 23.0221 13.0003C23.0221 18.5258 18.5268 23.0212 13.0013 23.0212Z'
      fill={color}
    />
    <path
      d='M18.6888 12.5941H13.4076V6.22949C13.4076 6.00521 13.2256 5.82324 13.0013 5.82324C12.777 5.82324 12.5951 6.00521 12.5951 6.22949V13.0003C12.5951 13.2246 12.777 13.4066 13.0013 13.4066H18.6888C18.9131 13.4066 19.0951 13.2246 19.0951 13.0003C19.0951 12.776 18.9131 12.5941 18.6888 12.5941Z'
      fill={color}
    />
  </svg>
);

export const ToastIcon: React.FC<IconProps> = ({
  color = '#02030B',
  width = 16,
  height = 16,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.0152 14.4849C15.05 14.5197 15.0776 14.561 15.0965 14.6065C15.1153 14.652 15.125 14.7008 15.125 14.75C15.125 14.7993 15.1153 14.848 15.0964 14.8935C15.0776 14.939 15.05 14.9804 15.0151 15.0152C14.9803 15.05 14.939 15.0776 14.8935 15.0965C14.848 15.1153 14.7992 15.125 14.75 15.125C14.7007 15.125 14.652 15.1153 14.6065 15.0964C14.561 15.0776 14.5196 15.05 14.4848 15.0151L7.99999 8.53036L1.51517 15.0151C1.44486 15.0855 1.34948 15.125 1.25002 15.125C1.15057 15.125 1.05519 15.0855 0.984855 15.0152C0.914525 14.9449 0.875009 14.8495 0.875 14.75C0.874991 14.6506 0.914491 14.5552 0.98481 14.4849L7.46965 7.99999L0.98481 1.51513C0.914491 1.4448 0.874991 1.34941 0.875 1.24996C0.875004 1.20071 0.884708 1.15195 0.903557 1.10646C0.922406 1.06096 0.950031 1.01963 0.984855 0.98481C1.01968 0.949991 1.06102 0.922373 1.10652 0.903532C1.15202 0.884691 1.20078 0.874996 1.25002 0.875C1.34948 0.875009 1.44486 0.914525 1.51517 0.984855L7.99999 7.46963L14.4848 0.984855C14.5196 0.950031 14.561 0.922406 14.6065 0.903557C14.652 0.884708 14.7007 0.875004 14.75 0.875C14.7992 0.874996 14.848 0.884691 14.8935 0.903532C14.939 0.922373 14.9803 0.949991 15.0151 0.98481C15.05 1.01963 15.0776 1.06096 15.0964 1.10646C15.1153 1.15195 15.125 1.20071 15.125 1.24996C15.125 1.2992 15.1153 1.34797 15.0965 1.39346C15.0776 1.43896 15.05 1.4803 15.0152 1.51513L8.53034 7.99999L15.0152 14.4849Z'
      fill={color}
    />
  </svg>
);
