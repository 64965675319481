import styled from 'styled-components';

export const StyledToast = styled.div`
  & .Toastify__toast-container {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  & .Toastify__toast {
    border-radius: 22px;
    width: 90%;
    margin: 0 auto;
    padding: 20px;
  }

  & .Toastify__toast-body {
    font-size: 20px;
    line-height: 130%;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    padding: 0;
  }

  & .Toastify__toast-icon {
    margin-bottom: 2px;
  }

  & .Toastify__toast--success {
    color: #02030b;
  }

  & .Toastify__toast--error {
    color: #800000;
  }

  & .Toastify__toast--warning {
    color: #c88000;
  }

  & .Toastify__toast--soon {
    color: #02030b;
  }
`;

export const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
