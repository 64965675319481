import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';

import { ROUTES } from './routes';

import { ProtectedRoute } from 'feature/auth';

import { H100, Layout } from 'shared/layout';

import { Menu } from 'widget/menu';

import { LoginPage } from 'pages/Login';

import { TaskPage } from 'pages/Tasks';
import { Mint } from 'pages/Mint';
import { Launch } from 'pages/Launch';
import { SandBox } from 'pages/SandBox';
import { Game } from 'pages/Game';
import { Profile } from 'pages/Profile';
import { Referral } from 'pages/Referral';
import { LeaderBoard } from 'pages/LeaderBoard';

import { Landing } from 'landing/Landing';

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        path: ROUTES.LOGIN,
        element: (
          <Layout>
            <LoginPage />
          </Layout>
        ),
      },
      {
        path: ROUTES.LANDING,
        element: <Landing />,
      },
      {
        path: ROUTES.APP,
        element: (
          <ProtectedRoute>
            <H100>
              <Layout>
                <Outlet />
              </Layout>
              <Menu />
            </H100>
          </ProtectedRoute>
        ),
        children: [
          {
            path: ROUTES.MINT,
            element: <Mint />,
          },
          {
            path: ROUTES.LAUNCH,
            element: <Launch />,
          },
          {
            path: ROUTES.GAME,
            element: <Game />,
          },
          {
            path: ROUTES.TASKS,
            element: <TaskPage />,
          },
          {
            path: ROUTES.RATINGS,
            element: <LeaderBoard />,
          },
          {
            path: ROUTES.SANDBOX,
            element: <SandBox />,
          },
          {
            path: ROUTES.PROFILE,
            element: <Profile />,
          },

          {
            path: ROUTES.REFERRAL,
            element: <Referral />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={ROUTES.LOGIN} />, // Перенаправляем на логин для несуществующих маршрутов
  },
]);
