import { useState } from 'react';
import { useInterval } from 'react-use';
import { Counter } from 'shared/counter';
import {
  BalanceRow,
  CircleBg,
  Content,
  Title,
  Wrapper,
} from './Balance.styled';
import { LotusIcon } from 'shared/icon';
import { colors } from 'app/colors';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';

import { useAppSelector } from 'app/store/rootStore';
import { selectBalance } from 'entities/user';
import { formatNumber } from 'shared/utils';

export const Balance = () => {
  const walletBalance = useAppSelector(selectBalance);
  const formatedBalance = formatNumber(walletBalance);
  return (
    <Wrapper>
      <Title> your balance</Title>
      <Content>
        <DivDecoration height={'100%'} />
        <BalanceRow>
          <Counter
            startValueOnce={true}
            startValue={formatedBalance}
            value={formatedBalance}
          />
          <CircleBg>
            <LotusIcon color={colors.blackColor} size='15' />
          </CircleBg>
        </BalanceRow>
        <DivDecoration isReverse height={'100%'} />
      </Content>
    </Wrapper>
  );
};
