import { colors } from 'app/colors';
import { IconProps } from './icon.types';

export const EnergyIcon: React.FC<IconProps> = ({
  color = colors.fourthColor,
  width = 20,
  height = 20,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.49988 19.0625C7.45366 19.0625 7.40801 19.0522 7.36624 19.0324C7.32446 19.0126 7.2876 18.9838 7.2583 18.9481C7.229 18.9123 7.208 18.8705 7.1968 18.8256C7.18561 18.7808 7.1845 18.734 7.19356 18.6887L8.39145 12.6993L3.64028 10.9176C3.59218 10.8995 3.54926 10.8699 3.51533 10.8313C3.48141 10.7928 3.45751 10.7464 3.44577 10.6964C3.43403 10.6464 3.4348 10.5942 3.44801 10.5446C3.46123 10.4949 3.48648 10.4493 3.52153 10.4118L12.2715 1.03675C12.3179 0.987053 12.3793 0.953841 12.4463 0.942153C12.5132 0.930465 12.5822 0.940938 12.6427 0.971985C12.7032 1.00303 12.7519 1.05296 12.7814 1.11419C12.811 1.17542 12.8198 1.24462 12.8064 1.3113L11.6085 7.30071L16.3597 9.08241C16.4078 9.10044 16.4507 9.13006 16.4847 9.16865C16.5186 9.20723 16.5425 9.25358 16.5542 9.30359C16.566 9.35361 16.5652 9.40575 16.552 9.4554C16.5388 9.50505 16.5135 9.55067 16.4785 9.58823L7.72846 18.9632C7.69921 18.9946 7.66382 19.0196 7.62449 19.0366C7.58517 19.0537 7.54275 19.0625 7.49988 19.0625Z'
      fill={color}
    />
  </svg>
);
