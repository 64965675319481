import React, { useEffect, useLayoutEffect, useRef } from 'react';
import * as THREE from 'three';
import { use3dModelContext } from './3dProvoider/3dProvoider';

export function Model() {
  const { isLoading, model, activeAnimation, animations } = use3dModelContext();
  const mixer = useRef<THREE.AnimationMixer | null>(null);

  useLayoutEffect(() => {
    if (!model) return; // Проверяем, загружена ли модель
    if (isLoading) {
      console.log('Model is loading, skipping animation setup.');
      return;
    }

    mixer.current = new THREE.AnimationMixer(model);

    if (animations.length > 0) {
      const clip = animations.find(
        (animation) => animation.name === activeAnimation
      );
      if (clip) {
        const action = mixer.current.clipAction(clip);
        action.play();
      } else {
        console.warn(
          `Animation "${activeAnimation}" not found in the loaded model.`
        );
      }
    }

    return () => {
      if (mixer.current) {
        mixer.current.stopAllAction();
      }
    };
  }, [model, activeAnimation, isLoading, animations]);

  useLayoutEffect(() => {
    const clock = new THREE.Clock();
    const animate = () => {
      requestAnimationFrame(animate);
      if (mixer.current) {
        mixer.current.update(clock.getDelta());
      }
    };
    animate();
  }, []);

  return <group>{model && <primitive object={model} />} </group>;
}
