// src/entities/tasks/api/tasksApi.ts
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TaskApiResponse } from './tasks.types';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const tasksApi = createApi({
  reducerPath: 'tasksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    fetchMainTasks: builder.query<TaskApiResponse, void>({
      query: () => '/main-quests',
    }),
    fetchDailyTasks: builder.query<TaskApiResponse, void>({
      query: () => '/daily-quest',
    }),
    fetchPartnerTasks: builder.query<TaskApiResponse, void>({
      query: () => '/partners-quests',
    }),
  }),
});

export const {
  useFetchMainTasksQuery,
  useFetchDailyTasksQuery,
  useFetchPartnerTasksQuery,
} = tasksApi;
