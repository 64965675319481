// Landing.styled.ts
import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--black-color);
  padding: 0 110px;
`;

export const Main = styled.div`
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
`;
