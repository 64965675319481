import * as React from 'react';
import { IconProps } from '../icon.types';

export const CoinIcon: React.FC<IconProps> = ({
  color = '#271F4E',
  width,
  height,
  size = 20,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='10' cy='10' r='10' fill='white' />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M14.3995 10.6425C14.8386 11.0371 15.0022 11.4288 15.0022 11.7415C15.0022 12.177 14.6849 12.7658 13.7481 13.302C12.8393 13.8222 11.5171 14.1758 10.0023 14.1758C8.48754 14.1758 7.16533 13.8222 6.2565 13.302C5.3197 12.7658 5.00241 12.177 5.00241 11.7415L5.00241 11.7402H4.00241V11.7415C4.00241 13.6382 6.68865 15.1758 10.0023 15.1758C13.316 15.1758 16.0022 13.6382 16.0022 11.7415C16.0022 9.84486 13.316 8.3073 10.0023 8.3073C8.26418 8.3073 6.69868 8.73035 5.60293 9.40635C5.1638 9.01176 5.00019 8.61998 5.00019 8.30729C5.00019 7.87183 5.31748 7.28301 6.25428 6.7468C7.1631 6.2266 8.48532 5.87305 10.0001 5.87305C11.5149 5.87305 12.8371 6.2266 13.7459 6.7468C14.6827 7.28301 15 7.87183 15 8.30729L15 8.30859H16V8.30729C16 6.41061 13.3137 4.87305 10.0001 4.87305C6.68643 4.87305 4.00019 6.41061 4.00019 8.30729C4.00019 10.204 6.68643 11.7415 10.0001 11.7415C11.7382 11.7415 13.3037 11.3185 14.3995 10.6425ZM13.4504 10.0239C12.5613 9.59093 11.359 9.3073 10.0023 9.3073C8.64446 9.3073 7.44135 9.5914 6.55202 10.025C7.44113 10.4579 8.64337 10.7415 10.0001 10.7415C11.3579 10.7415 12.561 10.4574 13.4504 10.0239Z'
      fill={color}
    />
  </svg>
);
