import { colors } from 'app/colors';
import { IconProps } from './icon.types';

export const CheckmarkIcon: React.FC<IconProps> = ({
  color = colors.fourthColor,
  width = 16,
  height = 16,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.9884 4.91923L6.91845 11.9906L2.67578 7.7479L3.61845 6.80523L6.91845 10.1052L13.0464 3.97656L13.9884 4.91923Z'
      fill={color}
    />
  </svg>
);
