import * as React from 'react';
import { IconProps } from '../icon.types';

interface BackIconProps extends IconProps {
  isReverse?: boolean;
}

export const BackIcon: React.FC<BackIconProps> = ({
  color = '#fff',
  width = 24,
  height = 24,
  size = 24,
  isReverse = false,
  ...props
}) => (
  <svg
    width={size || width}
    height={size || height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{
      transform: isReverse ? 'rotate(180deg)' : 'none',
      display: 'block',
    }}
    {...props}
  >
    <path
      d='M10.7651 4.9849C10.8 5.01971 10.8276 5.06105 10.8464 5.10654C10.8653 5.15203 10.875 5.2008 10.875 5.25004C10.875 5.29928 10.8653 5.34805 10.8464 5.39354C10.8276 5.43903 10.8 5.48036 10.7651 5.51518L4.65537 11.625L20.25 11.625C20.3495 11.625 20.4448 11.6645 20.5152 11.7349C20.5855 11.8052 20.625 11.9006 20.625 12C20.625 12.0995 20.5855 12.1949 20.5152 12.2652C20.4448 12.3355 20.3495 12.375 20.25 12.375L4.65537 12.375L10.7651 18.4849C10.8355 18.5552 10.875 18.6506 10.875 18.75C10.875 18.8495 10.8355 18.9449 10.7651 19.0152C10.6948 19.0855 10.5994 19.125 10.5 19.125C10.4006 19.125 10.3052 19.0855 10.2349 19.0152L3.48486 12.2652C3.45003 12.2304 3.4224 12.189 3.40355 12.1435C3.3847 12.098 3.375 12.0493 3.375 12C3.375 11.9508 3.3847 11.902 3.40355 11.8565C3.4224 11.811 3.45003 11.7697 3.48486 11.7349L10.2349 4.9849C10.2697 4.95007 10.311 4.92244 10.3565 4.90359C10.402 4.88474 10.4508 4.87504 10.5 4.87504C10.5492 4.87504 10.598 4.88474 10.6435 4.90359C10.689 4.92244 10.7303 4.95007 10.7651 4.9849Z'
      fill={color}
    />
  </svg>
);
