export const colors = {
  primaryColor: '#7f64f2',
  secondaryColor: '#d8f34f',
  tertiaryColor: '#271f4e',
  fourthColor: '#D8F34F',
  textInactiveColor: '#808084',
  inputBgColor: '#161521',
  notIntensiveColor: '#4A3C841A',
  secondaryTextColor: '#FFFFFF80',
  borderColor: '#FFFFFF1A',
  blackColor: '#02030b',
  whiteColor: '#fff',
  transperent: '#FFFFFF00',
  gradient: 'linear-gradient(180deg, #433581 0%, #02030B 40%)',
  progressBgColor: '#D8F34F40',
  fillBarColor: '#D8F34F',
  taskBG: '#111115',
  greenColor: '#1AFFD9',
  btnDoneBackground: '#262241',
  btnDoneDecoration: '#858294',
};
