// src/entities/tasks/ui/Tasks.tsx
import { FC, useState } from 'react';
import { TaskList } from '../Tasks.styled';
import { TaskItem } from './TaskItem';
import { ItemsListProps, TabType, Item } from '../tasks.types';
import { Soon } from 'shared/soon';
// import {
//   useFetchMainTasksQuery,
//   useFetchDailyTasksQuery,
//   useFetchPartnerTasksQuery,
// } from '../api';
import tasksData from './mockTasks.json';

export const Tasks: FC<ItemsListProps> = ({ tabType }) => {
  // const {
  //   data: mainTasks,
  //   error: mainError,
  //   isLoading: isLoadingMain,
  // } = useFetchMainTasksQuery();
  // const {
  //   data: dailyTasks,
  //   error: dailyError,
  //   isLoading: isLoadingDaily,
  // } = useFetchDailyTasksQuery();
  // const {
  //   data: partnerTasks,
  //   error: partnerError,
  //   isLoading: isLoadingPartner,
  // } = useFetchPartnerTasksQuery();

  // if (isLoadingMain || isLoadingDaily || isLoadingPartner) {
  //   return <div>Loading tasks...</div>;
  // }
  // if (mainError || dailyError || partnerError) {
  //   return <div>Error loading tasks.</div>;
  // }

  // let tasks: Item[] = [];
  // if (tabType === TabType.Social) {
  //   tasks = mainTasks?.data || [];
  // } else if (tabType === TabType.Daily) {
  //   tasks = dailyTasks?.data || [];
  // } else if (tabType === TabType.Partners) {
  //   tasks = partnerTasks?.data || [];
  // }

  // if (tabType === TabType.Daily) {
  //   return <Soon />;
  // }

  // if (tabType === TabType.Partners) {
  //   return <Soon />;
  // }

  // fake
  const [isCheckedTasks, setIsCheckedTasks] = useState<Set<number>>(new Set());

  const handleCheckTask = (taskId: number) => {
    setIsCheckedTasks((prev) => new Set(prev).add(taskId));
  };

  const tasks: Item[] = tasksData
    .filter((task) => task.tabType === tabType)
    .map((task) => ({
      ...task,
      tabType: task.tabType as TabType,
    }));

  if (tabType === TabType.Daily) {
    return <Soon />;
  }

  if (tabType === TabType.Partners) {
    return <Soon />;
  }

  return (
    <TaskList>
      {tasks.map((task) => (
        <TaskItem
          key={task.id}
          task={task}
          isChecked={isCheckedTasks.has(Number(task.id))}
          onCheck={handleCheckTask}
        />
      ))}
    </TaskList>
  );
};
