import { Button } from 'shared/button';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const SegmentWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  color: black;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin: 2px 0;
`;

export const Position = styled.div`
  width: 20%;
  font-size: 20px;
`;
export const Name = styled.div`
  width: 55%;
  font-size: 16px;
  text-align: start;
`;

export const IconWrapper = styled.div`
  margin-left: 5px;
`;
export const Balance = styled.div`
  width: 45%;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  justify-content: flex-end;
  text-align: end;
`;
