// Tabs.tsx
import React, { useState, FC, useEffect, useMemo, useCallback } from 'react';
import { TabsProps } from './tabs.types';
import {
  TabContainer,
  TabButton,
  TabContent,
  TabNavigation,
  TabSegment,
} from './tabs.styled';
import { TabsButtonDecoration } from 'shared/icon';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';

export const Tabs: FC<TabsProps> = ({
  defaultActiveTab,
  items,
  onTabChange,
}) => {
  const [activeTab, setActiveTab] = useState<string>(() => {
    if (defaultActiveTab) {
      return defaultActiveTab;
    } else {
      return items.length > 0 ? items[0].title : '';
    }
  });

  useEffect(() => {
    if (onTabChange) {
      onTabChange(activeTab);
    }
  }, [activeTab, onTabChange]);

  const handleTabClick = useCallback(
    (title: string) => {
      if (!items.find((item) => item.title === title)?.disable) {
        setActiveTab(title);
      }
    },
    [items]
  );

  const TabButtonMemo = React.memo(TabButton);

  const memoizedTabNavigation = useMemo(() => {
    return (
      <TabNavigation>
        {items.map((item) => (
          <div
            key={item.title}
            style={{
              height: item.title === activeTab ? '70px' : 'auto',
              display: 'flex',
              width: '140px',
              alignItems: 'center',
            }}
          >
            {item.title === activeTab && <DivDecoration height={'100%'} />}

            <TabButtonMemo
              isActive={item.title === activeTab}
              onClick={() => handleTabClick(item.title)}
              disabled={item.disable}
            >
              {item.title && item.title === activeTab ? (
                <>
                  <TabsButtonDecoration />
                  <span>{item.title}</span>
                </>
              ) : (
                <span>{item.title}</span>
              )}
            </TabButtonMemo>

            {item.title === activeTab && (
              <DivDecoration height={'100%'} isReverse />
            )}
          </div>
        ))}
      </TabNavigation>
    );
  }, [items, activeTab, TabButtonMemo, handleTabClick]);

  const memoizedTabContent = useMemo(() => {
    return (
      <TabContent>
        {items.map(
          (item) =>
            item.title === activeTab && (
              <TabSegment key={item.title}>{item.segment}</TabSegment>
            )
        )}
      </TabContent>
    );
  }, [items, activeTab]);

  return (
    <TabContainer>
      {memoizedTabNavigation}
      {memoizedTabContent}
    </TabContainer>
  );
};
