import { useState } from 'react';
import { colors } from 'app/colors';
import { ReferralsCount } from 'entities/user';
import { Button } from 'shared/button';
import { CentredWrapper } from 'shared/layout';
import { Text } from 'shared/typography';
import {
  ButtonWrapper,
  TextContainer,
  BlockWrapper,
  BlockContent,
  InputWrapper,
  BonusContainer,
  CopyContainer,
} from './Referral.styled';
import { Gradient } from 'shared/gradient';
import { Input } from 'shared/input';
import { Copy } from 'shared/copy';
import { selectUserId } from 'entities/user/model/slice';
import { useAppSelector } from 'app/store/rootStore';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';
import {
  generateReferralLink,
  generateShareURL,
  getVisibleReferralLink,
} from 'shared/utils/linkHelper';
import { RewardsIcon } from 'shared/icon';
import { PeopleIcon } from 'shared/icon';

export const ReferralContent = () => {
  const referralId = useAppSelector(selectUserId);
  const referralLink = generateReferralLink(referralId);
  const [inputValue, setInputValue] = useState<string>(referralLink);

  const handleShareClick = () => {
    const shareURL = generateShareURL(
      referralLink,
      'Join Game via the Referral Link and Get Rewards!'
    );
    window.open(shareURL, '_blank');
  };

  return (
    <CentredWrapper
      style={{
        justifyContent: 'space-evenly',
        width: '100%',
      }}
    >
      <BlockWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <DivDecoration height={'80%'} width={11} opacity={0.25} />
          <BonusContainer>
            <Text
              style={{
                color: colors.secondaryColor,
                fontSize: '12px',
              }}
            >
              Your Bonus
            </Text>
            <Text
              style={{
                color: colors.secondaryColor,
                fontWeight: 600,
                fontSize: '80px',
                marginTop: '12px',
              }}
            >
              20<span style={{ fontSize: '16px' }}>%</span>
            </Text>
          </BonusContainer>
          <DivDecoration isReverse height={'80%'} width={11} opacity={0.25} />
        </div>
        <BlockContent>
          <Text
            style={{
              color: colors.secondaryColor,
            }}
          >
            Referral Count
          </Text>
          <TextContainer
            style={{
              marginTop: '5px',
            }}
          >
            <ReferralsCount />
          </TextContainer>
        </BlockContent>
      </BlockWrapper>

      <BlockWrapper>
        <BlockContent>
          <Text
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px',
              letterSpacing: '4px',
              gap: '10px',
            }}
          >
            <PeopleIcon />
            Invite
          </Text>
          <TextContainer>
            <Text
              style={{
                fontSize: '12px',
                marginTop: '2px',
              }}
            >
              Friends via the referral link
            </Text>
          </TextContainer>
        </BlockContent>
        <BlockContent
          style={{
            marginTop: '18px',
          }}
        >
          <Text
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px',
              letterSpacing: '4px',
              gap: '10px',
            }}
          >
            <RewardsIcon />
            Get rewards
          </Text>
          <TextContainer>
            <Text
              style={{
                fontSize: '12px',
                marginTop: '2px',
              }}
            >
              Receive 10% of vour friends' staking
            </Text>
          </TextContainer>
        </BlockContent>
      </BlockWrapper>

      <BlockWrapper>
        <InputWrapper>
          <Input
            disabled
            bgColor={colors.inputBgColor}
            color={colors.whiteColor}
            placeholderColor={colors.whiteColor}
            type='text'
            value={getVisibleReferralLink(referralLink)}
            onChange={setInputValue}
            placeholder={getVisibleReferralLink(referralLink)}
          />
          <CopyContainer>
            <Copy textToCopy={inputValue || ''} />
          </CopyContainer>
        </InputWrapper>
        <ButtonWrapper>
          <Button
            color={colors.notIntensiveColor}
            borderColor={colors.primaryColor}
            decoration='basic'
            style={{ height: '56px', width: '160px' }}
            onClick={handleShareClick}
          >
            <Gradient color={colors.primaryColor}>
              {' '}
              <Text>Invite</Text>
            </Gradient>
          </Button>
        </ButtonWrapper>
      </BlockWrapper>
    </CentredWrapper>
  );
};
