import { colors } from 'app/colors';
import { Text } from 'shared/typography';
import styled from 'styled-components';

export const Wrapeer = styled.div``;

export const ButtonWrapper = styled.div`
  margin: 10px 0;
`;

export const StatsWrapper = styled.div`
  display: flex;
  margin: 5px 0;
  align-items: center;
`;
export const StatWrapper = styled.div`
  width: 80px;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
  height: 43px;
  color: ${colors.secondaryTextColor};

  &:nth-child(2) {
    border-right: 1px solid ${colors.borderColor};
    border-left: 1px solid ${colors.borderColor};
  }
  &:first-child {
    filter: blur(2px);
  }
  &:last-child {
    border-right: none;
    filter: blur(2px);
  }
`;
export const Stat = styled(Text)`
  font-size: 20px;
  line-height: 22px;
`;
