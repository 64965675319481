import { RobotScene } from 'feature/3d';
import { CentredWrapper } from 'shared/layout';
import { Timer } from 'shared/timer';
import { Text } from 'shared/typography';
import { StyledText } from './LaunchDate.styled';
import { Button } from 'shared/button';
import { Gradient } from 'shared/gradient';
import { colors } from 'app/colors';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'app';

const targetDate = new Date('2024-12-31T00:00:00Z');

export const LaunchDate = () => {
  return (
    <CentredWrapper>
      <RobotScene width='280px' height='350px' aspect={280 / 350} />

      <StyledText>SINT LAUNCH</StyledText>
      <Timer targetDate={targetDate} />
      <NavLink style={{ marginTop: 10 }} to={ROUTES.REFERRAL}>
        <Button decoration='basic' borderColor={colors.primaryColor} size='l'>
          <Gradient color={colors.primaryColor}>INVITE</Gradient>
        </Button>
      </NavLink>
    </CentredWrapper>
  );
};
