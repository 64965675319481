// entities/tasks/Tasks.styled.tsx
import styled from 'styled-components';

export const TaskList = styled.ul`
  margin: 18px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  overflow-y: auto;
  scroll-behavior: smooth;
`;

export const Task = styled.li`
  border: 0.5px solid var(--tertiary-color);
`;

export const TaskLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 7px 7px 14px;
`;

export const TaskName = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.96px;
  text-transform: uppercase;
`;

export const StyledButtonIcon = styled.div`
  margin-left: 3px;
  display: flex;
`;

export const TaskContainer = styled.div`
  display: flex;
  width: 65%;
  align-items: center;
  position: relative;
`;

export const TaskIcon = styled.div`
  margin-right: 13px;
  display: flex;
  align-items: center;
`;

export const TaskButton = styled.div`
  button {
    font-weight: 400;
    font-size: 24px;
    line-height: 92%;
    letter-spacing: 0.32px;
  }
`;

export const ModalIconWrapper = styled.div`
  background: #ffffff0d;
  height: 76px;
  margin: 0 5px;
  width: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(135deg, transparent 10px, #ffffff0d 0) top left,
    linear-gradient(-135deg, transparent 10px, #ffffff0d 0) top right,
    linear-gradient(-45deg, transparent 10px, #ffffff0d 0) bottom right,
    linear-gradient(45deg, transparent 10px, #ffffff0d 0) bottom left;
  background-size: 50% 50%;
  background-repeat: no-repeat;
`;

export const CheckMark = styled.div`
  display: flex;
  align-items: center;
  height: 85px;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OrangeLine = styled.div`
  width: 100%;
  height: 1px;
  background: #d8f34f;
  position: absolute;
`;
