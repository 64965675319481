import { Button } from 'shared/button';
import { Tabs } from 'shared/tabs';
import {
  Balance,
  IconWrapper,
  Name,
  Position,
  StyledButton,
  Wrapper,
} from './LeaderBoard.styled';
import { colors } from 'app/colors';
import { CoinIcon, LeaderIcon, LotusIcon } from 'shared/icon';
import {
  useLeaderboardDailyMutation,
  useLeaderboardMutation,
  useLeaderboardReferralsMutation,
} from 'entities/user/model/api';
import { useEffect, useLayoutEffect } from 'react';
import { Loader } from 'shared/loader';
import { useAppDispatch, useAppSelector } from 'app/store/rootStore';
import { selectLeaderboard } from 'entities/user';
import { CentredWrapper, H100 } from 'shared/layout';

const DATA = [
  {
    id: 5,
    idTelegram: 7329789875,
    walletBalance: 22600,
    username: 'jungleisme',
  },
  {
    id: 9,
    idTelegram: 987654321,
    walletBalance: 19750,
    username: 'john_doe',
  },
  {
    id: 1,
    idTelegram: 123123123,
    walletBalance: 18563,
    username: 'testUser',
  },
  {
    id: 55,
    idTelegram: 487819030,
    walletBalance: 10250,
    username: 'jungleisme',
  },
  {
    id: 2,
    idTelegram: 123456,
    walletBalance: 9000,
    username: 'test',
  },
  {
    id: 17,
    idTelegram: 987654700,
    walletBalance: 4000,
    username: 'test7test',
  },
  {
    id: 11,
    idTelegram: 987654200,
    walletBalance: 2250,
    username: 'test2test',
  },
  {
    id: 16,
    idTelegram: 987654600,
    walletBalance: 1750,
    username: 'test6test',
  },
  {
    id: 10,
    idTelegram: 987654100,
    walletBalance: 1250,
    username: 'testtest',
  },
  {
    id: 59,
    idTelegram: 1193794112,
    walletBalance: 1250,
    username: 'test14test',
  },
  {
    id: 31,
    idTelegram: 1193794100,
    walletBalance: 1250,
    username: 'test9test',
  },
  {
    id: 60,
    idTelegram: 285532720,
    walletBalance: 100,
    username: 'landskype',
  },
  {
    id: 30,
    idTelegram: 416402864,
    walletBalance: 100,
    username: 'user_Test',
  },
  {
    id: 29,
    idTelegram: 958620926,
    walletBalance: 100,
    username: 'user_Test',
  },
  {
    id: 28,
    idTelegram: 597381341,
    walletBalance: 100,
    username: 'user_Test',
  },
  {
    id: 27,
    idTelegram: 910380192,
    walletBalance: 100,
    username: 'user_Test',
  },
  {
    id: 26,
    idTelegram: 277666609,
    walletBalance: 100,
    username: 'user_Test',
  },
];
const getBorderColor = (index: number) => {
  if (index < 1) return colors.secondaryColor;
  if (index < 3) return colors.whiteColor;
  return colors.tertiaryColor;
};

const getBackgroundColor = (index: number) =>
  index < 1 ? '#D8F34F1A' : '#271F4E1A';

const getBgFilter = (index: number) =>
  index > 0 && index < 3 ? 'blur(10px)' : 'none';

const getBgColor = (index: number) =>
  index < 1 ? colors.secondaryColor : colors.whiteColor;

const getDecoration = (index: number) => (index < 3 ? 'basic' : undefined);

const getPosDecoration = (index: number) => {
  if (index < 1) return colors.secondaryColor;
  if (index < 3) return colors.whiteColor;
  return colors.transperent;
};

const renderTabContent = (
  tab: 'GLOBAL' | 'REFERRALS' | 'DAILY',
  data: any[]
) => (
  <Wrapper>
    {data.map(({ username, walletBalance }, index) => (
      <StyledButton
        key={`${tab}-${index}`}
        borderSize={index < 3 ? 0 : 0.5}
        borderColor={getBorderColor(index)}
        color={getBackgroundColor(index)}
        decoration={getDecoration(index)}
        style={{
          color: getBgColor(index),
          backdropFilter: getBgFilter(index),
        }}
      >
        <Position>
          {' '}
          <LeaderIcon value={index + 1} color={getPosDecoration(index)} />
        </Position>
        <Name>{username}</Name>
        <Balance>
          {walletBalance}
          <IconWrapper>
            <CentredWrapper>
              {' '}
              <CoinIcon />
            </CentredWrapper>
          </IconWrapper>
        </Balance>
      </StyledButton>
    ))}
  </Wrapper>
);

export const LeaderBoardTabs = () => {
  const [getLeaderboard, { isLoading: loading }] = useLeaderboardMutation();
  const [getLeaderboardDaily, { isLoading: isDailyLoading }] =
    useLeaderboardDailyMutation();
  const [getLeaderboardReferrals, { isLoading: isReferralsLoading }] =
    useLeaderboardReferralsMutation();

  const { top, daily, referrals } = useAppSelector(selectLeaderboard);

  useEffect(() => {
    getLeaderboard('');
    getLeaderboardDaily('');
    getLeaderboardReferrals('');
  }, []);

  const tabItems = [
    {
      title: 'DAILY',
      segment: !isDailyLoading ? (
        renderTabContent('DAILY', daily)
      ) : (
        <H100>
          <Loader />
        </H100>
      ),
      disable: false,
    },
    {
      title: 'GLOBAL',
      segment: !loading ? (
        renderTabContent('GLOBAL', top)
      ) : (
        <H100>
          {' '}
          <Loader />
        </H100>
      ),
      disable: false,
    },
    {
      title: 'REFERRALS',
      segment: !isReferralsLoading ? (
        renderTabContent('REFERRALS', referrals)
      ) : (
        <H100>
          {' '}
          <Loader />
        </H100>
      ),

      disable: false,
    },
  ];

  return (
    <Wrapper>
      <Tabs defaultActiveTab='GLOBAL' items={tabItems} />
    </Wrapper>
  );
};
