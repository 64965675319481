// tabs.styled.ts
import styled from 'styled-components';
import { TabButtonProps } from './tabs.types';

export const TabContainer = styled.div`
  height: 100%;
`;

export const TabNavigation = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
`;

export const TabButton = styled.button<TabButtonProps>`
  cursor: pointer;
  height: 56px;
  width: 100%;
  color: #ffffff;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  svg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  span {
    position: relative;
    z-index: 10;
  }
`;

export const TabContent = styled.div`
  color: var(--white-color);
  height: calc(100% - 68px);
`;

export const TabSegment = styled.div`
  height: calc(100% - 68px);
  color: #fff;
  overflow: scroll;
`;
