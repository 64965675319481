import { Text } from 'shared/typography';
import styled from 'styled-components';

export const IconWrapper = styled.div`
  background: #ffffff0d;
  height: 76px;
  margin: 0 5px;
  width: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(135deg, transparent 10px, #ffffff0d 0) top left,
    linear-gradient(-135deg, transparent 10px, #ffffff0d 0) top right,
    linear-gradient(-45deg, transparent 10px, #ffffff0d 0) bottom right,
    linear-gradient(45deg, transparent 10px, #ffffff0d 0) bottom left;
  background-size: 50% 50%;
  background-repeat: no-repeat;
`;

export const CheckMark = styled.div`
  display: flex;
  align-items: center;
  height: 85px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
