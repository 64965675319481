import { FC, useEffect, useState } from 'react';
import { differenceInSeconds } from 'date-fns';
import { TimerProps } from './timer.types';
import {
  TimerContainer,
  TimerDisplay,
  TimerUnit,
  TimerLabel,
} from './Timer.styled';

export const Timer: FC<TimerProps> = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const secondsLeft = differenceInSeconds(targetDate, new Date());

    if (secondsLeft <= 0) {
      return 'Time’s up!';
    }

    const days = Math.floor(secondsLeft / (3600 * 24));
    const hours = Math.floor((secondsLeft % (3600 * 24)) / 3600);
    const minutes = Math.floor((secondsLeft % 3600) / 60);
    const seconds = secondsLeft % 60;

    return `${days}: ${hours}: ${minutes}: ${seconds}`;
  };

  const [timeLeft, setTimeLeft] = useState<string>(calculateTimeLeft);

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedTimeLeft = calculateTimeLeft();

      if (updatedTimeLeft === '0: 0: 0: 0') {
        clearInterval(interval);
      }

      setTimeLeft(updatedTimeLeft);
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  const timeParts = timeLeft.split(':').map((part) => part.trim());

  return (
    <TimerContainer>
      <TimerDisplay>
        <TimerUnit>
          {timeParts[0]}
          <TimerLabel>d</TimerLabel>:
        </TimerUnit>
        <TimerUnit>
          {timeParts[1]}
          <TimerLabel>h</TimerLabel>:
        </TimerUnit>
        <TimerUnit>
          {timeParts[2]}
          <TimerLabel>m</TimerLabel>:
        </TimerUnit>
        <TimerUnit>
          {timeParts[3]}
          <TimerLabel>s</TimerLabel>
        </TimerUnit>
      </TimerDisplay>
    </TimerContainer>
  );
};
