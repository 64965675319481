import React, { createContext, useContext, useEffect, useState } from 'react';
import { TelegramUser, useTelegram } from 'feature/auth';
import { AuthContextProps } from './AuthProvoider.types';
import { useLoginMutation, useRegisterMutation } from 'entities/user/model/api';

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined
);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user: telegramUser, isReady, initData, startParams } = useTelegram(); // Получаем данные пользователя из Telegram
  const [login] = useLoginMutation();
  const [register] = useRegisterMutation();
  const [user, setUser] = useState<TelegramUser | null>(null);
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    if (telegramUser) {
      // Если получили данные из Telegram, пытаемся авторизовать или зарегистрировать пользователя
      handleLoginOrRegister();
    } else {
      setUser(null);
      setAuthenticated(false); // Если нет данных из Telegram, сбрасываем состояние
    }
  }, [telegramUser]);

  const handleLoginOrRegister = async () => {
    try {
      // Пробуем выполнить логин с данными из Telegram
      const result = await login(initData).unwrap();

      // const result = await login({
      //   data: 'query_id=AAHa1jATAAAAANrWMBM3P6zN&user=%7B%22id%22%3A123123123%2C%22first_name%22%3A%22TestAccount2%22%2C%22last_name%22%3A%22test%22%2C%22username%22%3A%22testUser%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1729612846&hash=cd75044309e4f3a73cadfb78c11c2df5deab10cfbf67ba04eb49cb2af0e9bf95&start_param=%221%22',
      // }).unwrap();

      setUser(result); // Если логин успешен, сохраняем данные пользователя
      setAuthenticated(true);
    } catch (loginError) {
      console.error('Login failed, attempting registration', loginError);

      // Если логин не удался, пробуем зарегистрировать пользователя
      try {
        const result = await register(initData).unwrap();
        setUser(result); // Если регистрация успешна, сохраняем данные пользователя
        setAuthenticated(true); // Устанавливаем статус авторизованного пользователя
      } catch (registerError) {
        console.error('Registration failed', registerError);

        setAuthenticated(false); // Если регистрация не удалась, статус остается неавторизованным
      }
    }
  };

  const logout = () => {
    setUser(null);
    setAuthenticated(false); // Сбрасываем состояние при выходе
  };

  return (
    <AuthContext.Provider
      value={{
        isTelegramReady: isReady,
        isAuthenticated,
        user,
        tgUser: telegramUser,
        login: handleLoginOrRegister,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
