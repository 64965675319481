import React from 'react';
import { CopyContainer } from './Copy.styled';
import { CopyProps } from './copy.types';
import { CopyIcon } from 'shared/icon';
import { useToast } from 'shared/toast';

export const Copy: React.FC<CopyProps> = ({ textToCopy, children }) => {
  const { showToast } = useToast();

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      showToast('Copied!', 'success');
    } catch (error) {
      showToast('Failed to copy text.', 'error');
    }
  };

  return (
    <CopyContainer onClick={handleCopy}>
      <CopyIcon />
      {children}
    </CopyContainer>
  );
};
