import { ROUTES } from 'app';
import { colors } from 'app/colors';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared/button';

import { useModal } from 'shared/modal';
import { SuccessMint } from './SuccessMint';
import { useEffect, useState } from 'react';
import { confetti } from '@tsparticles/confetti';
import { useAppDispatch } from 'app/store/rootStore';

import { Gradient } from 'shared/gradient';
import { updateBalance } from 'entities/user';
import { waiter } from 'shared/utils';
import { useMintMutation } from 'entities/user/model/api';
import { Loader } from 'shared/loader';
import { useToast } from 'shared/toast';

const MINT_REWARD = 100;

export const FakeMintButton = () => {
  const { setIsOpen, setModalProps } = useModal();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [mint, { isLoading, isSuccess, isError }] = useMintMutation();

  const closeHandle = () => {
    setIsOpen(false);
    void confetti({
      particleCount: 80,

      spread: 100,
      origin: { y: 1, x: 1 },
    });
    void confetti({
      particleCount: 80,

      spread: 100,
      origin: { y: 1, x: 0 },
    });
  };

  const onSuccess = async () => {
    setIsOpen(true);
    setModalProps({
      onRequestClose: closeHandle,
      children: <SuccessMint onNext={closeHandle} />,
    });
    navigate(ROUTES.LAUNCH, { replace: true });
    await waiter(3000);

    dispatch(updateBalance(MINT_REWARD)); // Обновляем баланс после ожидания
  };
  const onError = async () => {
    showToast('MINTING ERROR', 'error'); // Показываем сообщение об ошибке
  };

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      onError();
    }
  }, [isError]);

  const handleOpenModal = () => {
    mint({ agr: null })
      .then((response) => {
        if (response) {
          // Выполняем действия только при успешном ответе

          return waiter(500); // Ждём 500 мс перед обновлением баланса
        }
      })
      .then(() => {
        dispatch(updateBalance(MINT_REWARD)); // Обновляем баланс после ожидания
      })
      .catch((error) => {
        showToast('error', 'error'); // Показываем сообщение об ошибке
        console.error('Mint request failed:', error); // Логируем ошибку
      });
  };

  return (
    <>
      {/* Ожидаю модалку */}

      <Button
        isLoading={isLoading}
        onClick={() => mint('')}
        decoration='basic'
        borderColor={colors.primaryColor}
        size='l'
      >
        <Gradient color={colors.primaryColor}>CREATE AVATAR</Gradient>
      </Button>
    </>
  );
};
