// entities/tasks/ui/TaskItem.tsx
import { FC, useState, useEffect } from 'react';
import { TaskItemProps } from '../tasks.types';
import { Button } from 'shared/button';
import { Gradient } from 'shared/gradient';
import { CheckmarkIcon } from 'shared/icon/Checkmark';
import { colors } from 'app/colors';
import {
  Task,
  TaskName,
  StyledButtonIcon,
  TaskIcon,
  TaskContainer,
  TaskButton,
  TaskLink,
  OrangeLine,
} from '../Tasks.styled';
import { LogoIcon } from 'shared/icon/LogoIcon';
import { useModal } from 'shared/modal';
import { TaskModal } from './TaskModal';

// import { useSelector } from 'react-redux';
// import { selectMainQuests } from 'entities/user';

export const TaskItem: FC<TaskItemProps> = ({ task, isChecked, onCheck }) => {
  const { setIsOpen, setModalProps } = useModal();
  // const mainQuests = useSelector(selectMainQuests);

  const [isComplete, setIsComplete] = useState(task.isComplete);
  const [timer, setTimer] = useState<number | null>(null);
  const [isClaimable, setIsClaimable] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(false);

  const closeHandle = () => {
    setIsOpen(false);
  };

  const handleOpenModal = async () => {
    if ((isChecked || isComplete || isTimerActive) && task.link) {
      window.open(task.link, '_blank', 'noopener,noreferrer');
    } else {
      setIsOpen(true);
      setModalProps({
        onRequestClose: closeHandle,
        children: (
          <TaskModal task={task} onClose={closeHandle} onCheck={onCheck} />
        ),
      });
    }
  };

  const handleCheckClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setTimer(15);
    setIsTimerActive(true);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (timer !== null && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => (prev !== null && prev > 0 ? prev - 1 : 0));
      }, 1000);
    }

    if (timer === 0) {
      setIsClaimable(true);
      setIsTimerActive(false);
      if (interval !== null) clearInterval(interval);
    }

    return () => {
      if (interval !== null) clearInterval(interval);
    };
  }, [timer]);

  const handleClaimClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();

    // Устанавливаем задачу как завершённую
    setTimer(null);
    setIsClaimable(false);
    setIsComplete(true);

    console.log(`Task ${task.id} is now complete.`);
  };

  return (
    <Task>
      <TaskLink onClick={handleOpenModal}>
        <TaskContainer style={{ opacity: isComplete ? 0.5 : 1 }}>
          {isComplete && <OrangeLine />}
          <TaskIcon>
            <LogoIcon width={24} height={24} />
          </TaskIcon>
          <TaskName>{task.name}</TaskName>
        </TaskContainer>
        <TaskButton>
          {isComplete ? (
            <Button
              decoration='basic'
              borderColor={colors.btnDoneDecoration}
              size='s'
              color={colors.btnDoneBackground}
              style={{
                color: colors.secondaryColor,
                letterSpacing: '3.6px',
                fontSize: '14px',
              }}
            >
              <StyledButtonIcon style={{ margin: '0 4px 0 0' }}>
                <CheckmarkIcon width={16} height={16} />
              </StyledButtonIcon>
              DONE
            </Button>
          ) : timer === 0 && isClaimable ? (
            <Button
              decoration='reverse'
              borderColor={colors.whiteColor}
              color={colors.whiteColor}
              size='s'
              onClick={handleClaimClick}
              style={{
                letterSpacing: '3.6px',
                fontSize: '14px',
                color: colors.blackColor,
              }}
            >
              CLAIM
            </Button>
          ) : isChecked ? (
            timer !== null && timer > 0 ? (
              <Button
                onClick={handleCheckClick}
                decoration='basic'
                borderColor={colors.primaryColor}
                size='s'
              >
                {timer}s
              </Button>
            ) : (
              <Button
                onClick={handleCheckClick}
                decoration='reverse'
                borderColor={colors.whiteColor}
                color={colors.whiteColor}
                size='s'
                style={{
                  letterSpacing: '3.6px',
                  fontSize: '14px',
                  color: colors.blackColor,
                }}
              >
                CHECK
              </Button>
            )
          ) : (
            <Button
              decoration='basic'
              borderColor={colors.primaryColor}
              size='s'
            >
              <Gradient color={colors.primaryColor}>
                {task.reward}
                <StyledButtonIcon>
                  <LogoIcon width={20} height={20} />
                </StyledButtonIcon>
              </Gradient>
            </Button>
          )}
        </TaskButton>
      </TaskLink>
    </Task>
  );
};
