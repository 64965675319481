import * as React from 'react';
import { IconProps } from '../icon.types';
import { colors } from 'app/colors';

export const TabsButtonDecoration: React.FC<IconProps> = ({
  color = '#7F64F2',
  width = 99,
  height = 56,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 99 56'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M0.5 6.73122L4.38125 0.5H94.6188L98.5 6.73122V50.0043L94.6367 55.5H4.38125L0.5 49.2688V6.73122Z'
      fill='url(#paint0_radial_1933_622)'
      stroke={color}
    />
    <defs>
      <radialGradient
        id='paint0_radial_1933_622'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(49.5 28) rotate(90) scale(28 49.5)'
      >
        <stop stop-color={color} />
        <stop offset='1' stop-color='#493A8C' stop-opacity='0.19' />
      </radialGradient>
    </defs>
  </svg>
);
