import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store/rootStore';
import { State, User } from './types';
import { userApi } from './api';

const initialState: State = {
  account: {
    id: 1,
    parentId: 0,
    idTelegram: 123123123,
    username: 'testUser',
    firstName: 'TestAccount2',
    lastName: 'test',
    languageCode: null,
    isMinted: false,
    isBot: false,
    isPremium: false,
    walletAddress: null,
    walletBalance: 0,
    referralBalance: 0,
    referralCode: null,
    energy: 1000,
    expEnergy: 1,
    experience: 0,
    levelId: 1,
    passiveMining: 1,
    tapValue: 1,
    firstBoostDate: 0,
    updateBalanceAt: 0,
    referralsCount: 5,
    timezone: null,
    isWalletConnected: 0,
    createdAt: '2024-11-17T19:28:52.000Z',
    mainQuests: [],
    boosts: [],
    dailyTasks: [],
  },
  leaderboard: {
    top: [],
    daily: [],
    referrals: [],
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: () => initialState,
    setUser: (state, action: PayloadAction<User>) => {
      Object.assign(state, action.payload);
    },
    updateBalance: (state, action: PayloadAction<number>) => {
      state.account.walletBalance += action.payload;
    },
    updateEnergy: (state, action: PayloadAction<number>) => {
      state.account.energy = action.payload;
    },
    updateLevel: (state, action: PayloadAction<number>) => {
      state.account.levelId = action.payload;
    },
    updateExperience: (state, action: PayloadAction<number>) => {
      state.account.experience += action.payload;
    },
    updateExpEnergy: (state, action: PayloadAction<number>) => {
      state.account.expEnergy = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Обработка успешного выполнения login
    builder.addMatcher(
      userApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.account = Object.assign(state.account, payload);
      }
    );

    // Обработка успешного выполнения register
    builder.addMatcher(
      userApi.endpoints.register.matchFulfilled,
      (state, { payload }) => {
        state.account = Object.assign(state.account, payload);
      }
    );
    builder.addMatcher(
      userApi.endpoints.leaderboard.matchFulfilled,
      (state, { payload }) => {
        state.leaderboard.top = payload;
      }
    );
    builder.addMatcher(
      userApi.endpoints.leaderboardDaily.matchFulfilled,
      (state, { payload }) => {
        state.leaderboard.daily = payload;
      }
    );
    builder.addMatcher(
      userApi.endpoints.leaderboardReferrals.matchFulfilled,
      (state, { payload }) => {
        state.leaderboard.referrals = payload;
      }
    );
    // Re
    builder.addMatcher(userApi.endpoints.mint.matchFulfilled, (state) => {
      state.account.isMinted = true;
    });
  },
});

// Экспорт действий
export const {
  clearUser,
  setUser,
  updateBalance,
  updateEnergy,
  updateLevel,
  updateExperience,
  updateExpEnergy,
} = userSlice.actions;

// Селекторы для каждого свойства
export const selectUser = (state: RootState) => state.user.account;
export const selectUserId = (state: RootState) => state.user.account.id;
export const selectIsMinted = (state: RootState) => state.user.account.isMinted;
export const selectBalance = (state: RootState) =>
  state.user.account.walletBalance;
export const selectRefBalance = (state: RootState) =>
  state.user.account.referralBalance;
export const selectRefCount = (state: RootState) =>
  state.user.account.referralsCount;
export const selectEnergy = (state: RootState) => state.user.account.energy;
export const selectLevel = (state: RootState) => state.user.account.levelId;
export const selectExperience = (state: RootState) =>
  state.user.account.experience;
export const selectExpEnergy = (state: RootState) =>
  state.user.account.expEnergy;
export const selectDailyTasks = (state: RootState) =>
  state.user.account.dailyTasks;
export const selectLeaderboard = (state: RootState) => state.user.leaderboard;
export const selectMainQuests = (state: RootState) =>
  state.user.account.mainQuests;
// Экспорт редьюсера
export const userReducer = userSlice.reducer;
