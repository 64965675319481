import { RobotScene } from 'feature/3d';
import { Balance } from 'feature/balance';
import { H100, Header } from 'shared/layout';
import { ProfileContent } from 'widget/profile';

export const Profile = () => {
  return (
    <H100>
      <Header>
        <div />
        <Balance />
        <div />
      </Header>
      <ProfileContent />
    </H100>
  );
};
