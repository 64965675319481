import * as React from 'react';
import { IconProps } from '../icon.types';

export const CopyIcon: React.FC<IconProps> = ({
  color = '#fff',
  width = 24,
  height = 24,
  size = 24,
  ...props
}) => (
  <svg
    width={size || width}
    height={size || height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M20.2222 6.75L20.25 6.77419V19.1613C20.25 19.7183 20.0317 20.2423 19.6354 20.6368C19.2381 21.0322 18.7099 21.25 18.1481 21.25H9.85185C9.29005 21.25 8.76187 21.0322 8.36461 20.6368C7.96827 20.2423 7.75 19.7183 7.75 19.1613L7.77778 6.75H20.2222ZM16.625 2H3.375C3.16797 2 3 2.16797 3 2.375V16.625C3 16.832 3.16797 17 3.375 17C3.58203 17 3.75 16.832 3.75 16.625V2.75H16.625C16.832 2.75 17 2.58203 17 2.375C17 2.16797 16.832 2 16.625 2ZM20.2222 6H7.77778C7.34838 6 7 6.34678 7 6.77419V19.1613C7 19.9194 7.29673 20.6321 7.8355 21.1684C8.37428 21.7046 9.09028 22 9.85185 22H18.1481C18.9097 22 19.6257 21.7046 20.1645 21.1684C20.7033 20.6321 21 19.9194 21 19.1613V6.77419C21 6.34678 20.6516 6 20.2222 6Z'
      fill={color}
    />
  </svg>
);
