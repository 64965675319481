import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react';
import {
  generateData,
  GeneratedElement,
  getRandomSize,
} from '../utils/generator';
import { Button, ButtonSize } from 'shared/button';
import { Gradient } from 'shared/gradient';
import { Carousel } from 'shared/carousel';
import { CentredWrapper, H100 } from 'shared/layout';
import { colors } from 'app/colors';
import { GradientTimer } from 'shared/gradientTimer';
import { Step, StepDirection, Steps } from 'shared/steps';
import {
  BubbleArea,
  GameButton,
  GameWrapper,
  InfoWrapper,
  RobotWrapper,
  Streak,
} from '../Game.styled';
import { Energy } from 'feature/energy';
import { useAppDispatch, useAppSelector } from 'app/store/rootStore';
import { selectEnergy, updateBalance, updateEnergy } from 'entities/user';
import { Text } from 'shared/typography';
import { RobotScene } from 'feature/3d';

const transformSequencesToSteps = (sequences: GeneratedElement[]): Step[] => {
  return sequences.map((element) => ({
    label: element.value.toString(),
    value: element.step.toString(),
    color: element.color,
  }));
};

const MemoizedGameButton = React.memo(GameButton);
const MemoizedGradientTimer = React.memo(GradientTimer);
const MemoizedSteps = React.memo(Steps);

const MAX_PRIMARY = 7;
const MAX_FAKE = 2;
const BUTTON_SIZE = 70;
const TOTAL_SEQUENCES = 3;

const SCENE_SIZE = {
  width: 280,
  height: 350,
};

const TIMER_START = 10100;
const TIME_DECREMENT = 100;

export const MainGame: React.FC = () => {
  const energy = useAppSelector(selectEnergy);
  const dispatch = useAppDispatch();

  const [gameStarted, setGameStarted] = useState(false);
  const [clicked, setClicked] = useState<any>([]);
  const [sequences, setSequences] = useState<GeneratedElement[][]>([]);
  const [currentSequenceIndex, setCurrentSequenceIndex] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  const [sequenceCompleted, setSequenceCompleted] = useState(false);
  const [sequenceFailed, setSequenceFailed] = useState(false);

  const [streak, setStreak] = useState(0);

  const [timer, setTimer] = useState(TIMER_START);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const carouselRef = useRef<any>(null);

  const [randomPositions, setRandomPositions] = useState<
    { top: number; left: number; size: ButtonSize }[]
  >([]);

  const generate = useCallback(() => {
    const generatedSequences: GeneratedElement[][] = sequences;
    for (let i = 0; i < TOTAL_SEQUENCES; i++) {
      const { primaryArray } = generateData(MAX_PRIMARY, MAX_FAKE);
      generatedSequences.push(primaryArray);
    }
    setSequences(generatedSequences);
    setGameStarted(true);
  }, [sequences]);

  const handleClick = useCallback(
    (step: number) => {
      if (!gameStarted || sequenceCompleted || sequenceFailed) return;
      const currentSequence = sequences[currentSequenceIndex];
      setClicked((prev: boolean[]) => [...prev, true]);
      dispatch(updateEnergy(energy - 1));
      if (step === currentSequence[currentStep].step) {
        const isLastStep = currentStep + 1 === currentSequence.length;
        if (isLastStep) {
          completeSequence();
        } else {
          setCurrentStep((prevStep) => prevStep + 1);
        }
      } else {
        failSequence();
      }
    },
    [
      gameStarted,
      sequenceCompleted,
      sequenceFailed,
      currentSequenceIndex,
      currentStep,
      energy,
      dispatch,
    ]
  );

  useLayoutEffect(() => {
    if (gameStarted && sequences[currentSequenceIndex]) {
      generateRandomPositions(sequences[currentSequenceIndex]);
    }
  }, [currentSequenceIndex, gameStarted]);

  const completeSequence = useCallback(() => {
    setSequenceCompleted(true);

    setTimeout(() => {
      const nextSequenceIndex = currentSequenceIndex + 1;
      if (nextSequenceIndex < sequences.length) {
        if (currentSequenceIndex > sequences.length - 4) {
          generate();
        }
        setCurrentSequenceIndex(nextSequenceIndex);
        setCurrentStep(0);

        setSequenceCompleted(false);

        dispatch(updateBalance(currentSequence.length));
        setStreak((prevStreak) => prevStreak + 1);

        // Уменьшаем начальное время таймера
        const newTimer = TIMER_START - TIME_DECREMENT * (streak + 1);
        setTimer(newTimer > 0 ? newTimer : 0);

        setClicked([]);
        carouselRef.current.goToSlide(nextSequenceIndex);

        resetTimer(newTimer > 0 ? newTimer : 0);
      }
    });
  }, [
    currentSequenceIndex,
    currentStep,
    sequences,
    dispatch,
    generate,
    streak,
  ]);

  const failSequence = useCallback(() => {
    setSequenceFailed(true);
    clearInterval(timerRef.current!);

    setTimeout(() => {
      const nextSequenceIndex = currentSequenceIndex + 1;
      if (nextSequenceIndex < sequences.length) {
        if (currentSequenceIndex > sequences.length - 4) {
          generate();
        }
        setCurrentSequenceIndex(nextSequenceIndex);
        setCurrentStep(0);
        setSequenceFailed(false);
        setStreak(0);
        setTimer(TIMER_START);
        setClicked([]);
        carouselRef.current.goToSlide(nextSequenceIndex);

        resetTimer(TIMER_START);
      }
    });
  }, [currentSequenceIndex, sequences, generate]);

  useEffect(() => {
    if (timer < TIME_DECREMENT) {
      failSequence();
    }
  }, [timer]);

  const resetTimer = (time: number) => {
    clearInterval(timerRef.current!);
    setTimer(time);

    timerRef.current = setInterval(() => {
      setTimer((prev) => {
        return prev - 100;
      });
    }, 100);
  };

  useEffect(() => {
    if (gameStarted) {
      resetTimer(timer);
    }

    return () => {
      clearInterval(timerRef.current!);
    };
  }, [gameStarted]);

  const generateRandomPositions = useCallback(
    (elements: GeneratedElement[]) => {
      if (!containerRef.current) return;

      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;

      const sizes: ButtonSize[] = ['m', 'l'];
      const positions: { top: number; left: number; size: ButtonSize }[] = [];

      while (positions.length < elements.length) {
        const top = Math.random() * (containerHeight - BUTTON_SIZE);
        const left = Math.random() * (containerWidth - BUTTON_SIZE);
        const isOverlapping = positions.some(
          (pos) =>
            Math.abs(pos.top - top) < BUTTON_SIZE &&
            Math.abs(pos.left - left) < BUTTON_SIZE
        );

        if (!isOverlapping) {
          const size: ButtonSize =
            sizes[Math.floor(Math.random() * sizes.length)];
          positions.push({ top, left, size });
        }
      }
      setRandomPositions(positions);
    },
    [currentSequenceIndex]
  );
  const currentSequence = sequences[currentSequenceIndex] || [];

  return (
    <GameWrapper>
      <RobotWrapper>
        <RobotScene
          width={SCENE_SIZE.width + 'px'}
          height={SCENE_SIZE.height + 'px'}
          aspect={280 / 350}
        />
      </RobotWrapper>
      {!gameStarted ? (
        <CentredWrapper>
          <div
            style={{ width: SCENE_SIZE.width, height: SCENE_SIZE.height }}
          ></div>
          <Button
            onClick={generate}
            decoration='basic'
            size='l'
            style={{ zIndex: 10 }}
            borderColor={colors.secondaryColor}
          >
            <Gradient color={colors.secondaryColor}>
              <Text style={{ fontSize: 20 }}>Play</Text>
            </Gradient>
          </Button>
        </CentredWrapper>
      ) : (
        <>
          <Text>
            <Streak>{timer / 1000}s</Streak>
          </Text>
          <Carousel
            ref={carouselRef}
            arrows={false}
            draggable={false}
            swipeable={false}
          >
            {sequences.map((sequence, seqIndex) => (
              <MemoizedSteps
                key={`seq-${seqIndex}`}
                isFailed={sequenceFailed}
                steps={transformSequencesToSteps(sequence)}
                isActive={seqIndex === currentSequenceIndex}
                currentStep={currentStep}
                direction={StepDirection.Horizontal}
              />
            ))}
          </Carousel>
          <BubbleArea ref={containerRef}>
            {currentSequence.map((element, index) => {
              const { top, left, size } = randomPositions[index] || {
                top: -1000,
                left: -2000,
                size: 'm',
              };

              return (
                <MemoizedGameButton
                  isAnimating={clicked[index]}
                  key={`${index}-${element.step}-${element.value}-${element.color}`}
                  style={{
                    position: 'absolute',
                    top: `${top}px`,
                    left: `${left}px`,
                  }}
                  shape='round'
                  size={size}
                  onClick={() => {
                    handleClick(element.step);
                  }}
                  disabled={sequenceCompleted || sequenceFailed}
                >
                  <MemoizedGradientTimer
                    color={element.color}
                    borderWidth={15}
                    content={
                      <div style={{ fontSize: 20, fontWeight: 600 }}>
                        {element.value}
                      </div>
                    }
                    isBlum={false}
                    static
                    status='active'
                  />
                </MemoizedGameButton>
              );
            })}
          </BubbleArea>
          <InfoWrapper>
            <Streak>{streak > 0 && `x${streak}`}</Streak>
            <Energy />
          </InfoWrapper>
        </>
      )}
    </GameWrapper>
  );
};
