import styled from 'styled-components';
import { LogoProps } from './logo.types';

export const Wrapper = styled.div`
  text-align: center;
`;
export const LogoText = styled.div<LogoProps>`
  font-size: 22px;

  font-weight: 400;
  font-family: 'Dune Rise';
  line-height: 28.6px;
  letter-spacing: 0.74em;
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
`;
