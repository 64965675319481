// entities/tasks/ui/TaskModal.tsx
import { FC } from 'react';
import { colors } from 'app/colors';
import { BalanceRow } from 'feature/balance';

import { Button } from 'shared/button';

import { CheckmarkIcon } from 'shared/icon/Checkmark';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';
import { Text } from 'shared/typography';
import {
  CheckMark,
  ModalIconWrapper,
  ModalWrapper,
  StyledButtonIcon,
} from '../Tasks.styled';
import { Gradient } from 'shared/gradient';
import { LogoIcon } from 'shared/icon/LogoIcon';

import { TaskModalProps } from '../tasks.types';

export const TaskModal: FC<TaskModalProps> = ({ task, onClose, onCheck }) => {
  const handleOpenLink = () => {
    if (task.link) {
      window.open(task.link, '_blank', 'noopener,noreferrer');
    }
    onCheck(Number(task.id));
    onClose();
  };
  return (
    <ModalWrapper>
      <CheckMark>
        <DivDecoration width={10} height={'100%'} />
        <ModalIconWrapper>
          <CheckmarkIcon width={50} height={50} />
        </ModalIconWrapper>
        <DivDecoration width={10} isReverse height={'100%'} />
      </CheckMark>
      <div style={{ marginTop: 20, marginBottom: 14, textAlign: 'center' }}>
        <Text style={{ fontSize: 20, lineHeight: 2 }}>{task.name}</Text>
      </div>
      <div style={{ marginBottom: 20 }}>
        <BalanceRow>
          <span>{task.reward}</span>
          <StyledButtonIcon style={{ margin: '0 0 0 4px' }}>
            {' '}
            <LogoIcon width={25} height={25} />
          </StyledButtonIcon>
        </BalanceRow>
      </div>
      <Button
        onClick={handleOpenLink}
        decoration='basic'
        borderColor={colors.primaryColor}
        size='l'
      >
        <Gradient color={colors.primaryColor}>GET IN</Gradient>
      </Button>
    </ModalWrapper>
  );
};
