import { Logo } from 'shared/logo';
import Embrion from 'shared/icon/png/embrion.png';
import { FakeMintButton } from 'feature/fakeMint';
import { CentredWrapper } from 'shared/layout';
import { AnimatedItem, StyledText } from './FakeMint.styled';

export const FakeMint = () => {
  return (
    <CentredWrapper>
      {' '}
      <AnimatedItem>
        <img
          src={Embrion}
          alt='Example'
          style={{ width: '200px', height: '200px' }}
        />
      </AnimatedItem>
      <Logo />
      <StyledText>
        We have compiled a selection of topics that may help you
      </StyledText>
      <FakeMintButton />
    </CentredWrapper>
  );
};
