// src/app/rootReducer.ts
import { combineReducers } from '@reduxjs/toolkit';
import { tasksApi } from 'entities/tasks/api';
import tasksReducer from 'entities/tasks/slice';
import { userApi, userReducer } from 'entities/user';

export const rootReducer = combineReducers({
  user: userReducer,
  [userApi.reducerPath]: userApi.reducer,
  tasks: tasksReducer,
  [tasksApi.reducerPath]: tasksApi.reducer,
});
