import { colors } from 'app/colors';
import { LogoText, Wrapper } from './Logo.styled';
import { LogoProps } from './logo.types';

export const Logo: React.FC<LogoProps> = ({
  fontSize = '24px',
  color = colors.whiteColor,
  isLoading = false,
}) => (
  <Wrapper>
    {!isLoading ? (
      <LogoText fontSize={fontSize} color={color}>
        SINT
      </LogoText>
    ) : (
      <svg
        width='116'
        height='18'
        viewBox='0 0 116 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5.27666 0.4H18.1027V1.874H5.27666C4.36733 1.874 3.58999 2.19667 2.94466 2.842C2.29932 3.48733 1.97666 4.26467 1.97666 5.174C1.97666 6.08333 2.29932 6.86067 2.94466 7.506C3.58999 8.13667 4.36733 8.452 5.27666 8.452H13.3287C14.6487 8.452 15.7707 8.92133 16.6947 9.86C17.6333 10.784 18.1027 11.906 18.1027 13.226C18.1027 14.546 17.6333 15.6753 16.6947 16.614C15.7707 17.538 14.6487 18 13.3287 18H0.502658V16.526H13.3287C14.238 16.526 15.0153 16.2033 15.6607 15.558C16.306 14.9127 16.6287 14.1353 16.6287 13.226C16.6287 12.3167 16.306 11.5467 15.6607 10.916C15.0153 10.2707 14.238 9.948 13.3287 9.948H5.27666C3.95666 9.948 2.82732 9.486 1.88866 8.562C0.964658 7.62333 0.502658 6.494 0.502658 5.174C0.502658 3.854 0.964658 2.732 1.88866 1.808C2.82732 0.869333 3.95666 0.4 5.27666 0.4ZM38.7827 18V0.4H40.2567V18H38.7827ZM63.5019 2.974C65.2179 1.258 67.2932 0.4 69.7279 0.4C72.1626 0.4 74.2379 1.258 75.9539 2.974C77.6699 4.69 78.5279 6.76533 78.5279 9.2V18H77.0539V9.2C77.0539 7.88 76.7239 6.65533 76.0639 5.526C75.4186 4.39667 74.5312 3.50933 73.4019 2.864C72.2726 2.204 71.0479 1.874 69.7279 1.874C68.4079 1.874 67.1832 2.204 66.0539 2.864C64.9246 3.50933 64.0299 4.39667 63.3699 5.526C62.7246 6.65533 62.4019 7.88 62.4019 9.2V18H60.9279V9.2C60.9279 6.76533 61.7859 4.69 63.5019 2.974ZM97.8974 1.874V0.4H115.497V1.874H107.445V18H105.949V1.874H97.8974Z'
          fill='white'
        />
      </svg>
    )}
  </Wrapper>
);
