import styled from 'styled-components';
import { ProgressBarProps } from './progressBar.types';

export const ProgressBarContainer = styled.div<
  Pick<ProgressBarProps, 'height' | 'backgroundColor' | 'width'>
>`
  width: ${({ width }) => width};
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: relative;
  height: ${({ height }) => height};
  z-index: 0;
  // overflow: hidden;
`;

export const Filler = styled.div<
  Pick<ProgressBarProps, 'color' | 'blurHeight' | 'blurPosition' | 'blur'>
>`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${({ color }) => color};
  z-index: 1;
  transition: transform 0.2s ease-in;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: ${({ blurHeight }) => blurHeight};
    top: ${({ blurPosition }) => blurPosition};
    background: linear-gradient(
      90deg,
      rgba(216, 243, 79, 0) 0%,
      rgba(216, 243, 79, 1) 100%
    );
    filter: ${({ blur }) => blur};
    z-index: -1;
  }
`;
