import { ReactNode } from 'react';

export interface Step {
  label: ReactNode | string;
  value: string;
  color?: string;
  timer?: number;
}

export interface CustomStepsProps {
  steps: Step[];
  direction?: StepDirection;
  isActive?: boolean;
  currentStep: number;
  isFailed: boolean;
}

export enum StepStatus {
  Active = 'active',
  Success = 'success',
  Failed = 'failed',
}

export enum StepDirection {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}
