import { Balance } from 'feature/balance';
import { Level } from 'feature/level';
import { Button } from 'shared/button';
import { H100, Header } from 'shared/layout';
import { FakeMint } from 'widget/fakeMint';

export const Mint = () => {
  return (
    <H100 style={{ overflow: 'hidden' }}>
      <Header>
        <Level />
        <Balance />
      </Header>
      <FakeMint />
    </H100>
  );
};
