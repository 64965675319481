import { H100, Header } from 'shared/layout';

import { ReferralContent } from 'widget/referral/ReferralContent';
import { Navback } from 'feature/navback';
import { PageTitle } from 'shared/typography';

export const Referral = () => {
  return (
    <H100>
      <Header>
        <Navback />
        <PageTitle>Invite Friends</PageTitle>
        <div style={{ width: '24px' }} />
      </Header>
      <ReferralContent />
    </H100>
  );
};
