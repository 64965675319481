import { Canvas } from '@react-three/fiber';
import { OrbitControls, Sparkles } from '@react-three/drei';
import { Model } from '../model/3dModel';

export function RobotScene({
  width = '300px',
  height = '300px',
  distance = 2.5,
  aspect = 1,
}: {
  width?: string;
  height?: string;
  distance?: number;
  aspect?: number;
}) {
  const handleSceneCreated = (state: any) => {
    console.log('Scene created:', state);
    // Здесь можно проверить, есть ли объект модели в сцене
    const model = state.scene.getObjectByName('Scene');
    if (model) {
      console.log('Model is present in the scene');
    } else {
      console.log('Model is not yet loaded');
    }
  };

  return (
    <>
      <Canvas
        style={{ width, height, background: 'transparent' }}
        camera={{ position: [0, 0, 0], fov: 30, aspect }}
        onCreated={handleSceneCreated}
      >
        <ambientLight intensity={1} />{' '}
        <spotLight
          position={[10, 10, 10]}
          angle={0.3}
          intensity={1}
          castShadow
        />
        {/* Ярче направленный свет */}
        <directionalLight position={[1, 1, 1]} intensity={2.5} />
        <directionalLight position={[-1, 1, -1]} intensity={2.5} />
        <Model />
        <OrbitControls
          rotateSpeed={1}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 2}
          minDistance={distance}
          maxDistance={distance}
        />
      </Canvas>
    </>
  );
}
