import styled, { keyframes } from 'styled-components';

const loaderAnimation = keyframes`
  0%, 30% {
    background-position: 0 0, 50% 0, 100% 0, 0 50%, 100% 50%, 0 100%, 50% 100%, 100% 100%;
  }
  70%, 100% {
    background-position: 15% 15%, 50% 0, 85% 15%, 0 50%, 100% 50%, 15% 85%, 50% 100%, 85% 85%;
  }
`;

export const AnimatedLoader = styled.div<{
  size?: string;
  primaryColor?: string;
  secondaryColor?: string;
}>`
  width: ${({ size = '30px' }) => size};
  aspect-ratio: 1; /* Сохраняет пропорции 1:1 */

  --g: no-repeat
    radial-gradient(
      farthest-side,
      ${({ secondaryColor }) => secondaryColor} calc(95% - 2px),
      ${({ primaryColor }) => primaryColor} calc(100% - 2px) 98%,
      ${({ secondaryColor }) => secondaryColor} 101%
    );

  background: var(--g), var(--g), var(--g), var(--g), var(--g), var(--g),
    var(--g), var(--g);
  background-size: ${({ size = '30px' }) =>
    `calc(${size} / 5) calc(${size} / 5)`};
  animation: ${loaderAnimation} 0.5s infinite alternate linear;
`;
