export enum ROUTES {
  APP = '/app',
  TASKS = '/app/tasks',
  RATINGS = '/app/RATINGS',
  MINT = '/app/mint',
  LAUNCH = '/app/launch',
  GAME = '/app/game',
  BOOSTS = '/app/boosts',
  PROFILE = '/app/profile',
  SANDBOX = '/app/sandbox',
  REFERRAL = '/app/referral',
  LANDING = '/app/landing',
  LOGIN = '/',
}
