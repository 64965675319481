import React from 'react';
import { StyledSVG } from './GradientTimer.styled';
import { lightenColor } from 'shared/utils/colorUtils';

interface GradientCircleProps {
  radius: number;
  borderWidth: number;
  gradientId: string;
  computedColor: string;
  circumference: number;
  isStatic: boolean;
  offset: number;
}

export const GradientCircle: React.FC<GradientCircleProps> = ({
  radius,
  borderWidth,
  gradientId,
  computedColor,
  circumference,
  isStatic,
  offset,
}) => {
  const lightenedColor1 = computedColor;
  const lightenedColor2 = computedColor;

  const transform = isStatic ? '' : 'rotate(-90 50 50)';
  const transition = isStatic ? '' : 'stroke-dashoffset 0.05s linear';

  return (
    <StyledSVG viewBox='0 0 100 100'>
      <circle
        cx='50'
        cy='50'
        r={radius}
        stroke='transperent'
        strokeWidth={borderWidth}
        fill='none'
      />
      <circle
        cx='50'
        cy='50'
        r={radius}
        stroke={`url(#${gradientId})`}
        strokeWidth={borderWidth}
        fill='none'
        strokeDasharray={circumference}
        strokeDashoffset={isStatic ? 0 : offset}
        transform={transform}
        style={{ transition }}
      />
      <defs>
        <linearGradient id={gradientId} x1='0%' y1='0%' x2='100%'>
          <stop offset='0%' stopColor={lightenedColor1} />
          <stop offset='100%' stopColor={lightenedColor2} />
        </linearGradient>
      </defs>
    </StyledSVG>
  );
};
