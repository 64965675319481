import { Text } from 'shared/typography';
import styled, { css } from 'styled-components';

export const ButtonWrapper = styled.div`
  margin: 20px 0;
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
export const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
  width: 100%;
`;
export const TextContainer = styled(Text)`
  color: var(--secondary-color);
  font-size: 32px;
  font-weight: 600;
`;

export const InputWrapper = styled.div`
  width: 100%;
  height: 56px;
  position: relative;
`;

export const BonusContainer = styled.div`
  width: 120px;
  margin: 10px;
  color: var(--secondary-color);
  background-color: #ffffff19;
  padding: 17px 7px 20px;
  clip-path: polygon(
    10px 0,
    calc(100% - 10px) 0,
    100% 10px,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    10px 100%,
    0 calc(100% - 10px),
    0 10px
  );
`;

export const CopyContainer = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
`;
