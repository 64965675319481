import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
  useLayoutEffect,
} from 'react';
import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';
import { TextureLoader } from 'three';

// Определяем тип контекста для 3D модели
interface ModelContextType {
  isLoading: boolean;
  modelUrl: string;
  setModelUrl: (url: string) => void;
  activeAnimation: string;
  setActiveAnimation: (name: string) => void;
  setModelTextures: (textures: {
    baseColor?: string;
    emissive?: string;
    metallic?: string;
    normal?: string;
    roughness?: string;
  }) => void;
  model: THREE.Group | null;
  animations: THREE.AnimationClip[];
}

// Создаем контекст для 3D модели
const ModelContext = createContext<ModelContextType | undefined>(undefined);

// Провайдер для 3D модели
export const Model3dProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [modelUrl, setModelUrl] = useState('/model/sint.glb');
  const [activeAnimation, setActiveAnimation] = useState('IDLE');
  const [textures, setTextures] = useState({
    baseColor: '/model/textures/Sohp2_Soph2_0_Body_BaseColor.png',
    metallic:
      '/model/textures/Sohp2_Soph2_0_Body_Metallic_png-Sohp2_Soph2_0_Body_Roughness_png.png',
  });

  const [model, setModel] = useState<THREE.Group | null>(null);
  const [animations, setAnimations] = useState<THREE.AnimationClip[]>([]);
  const textureLoader = useMemo(() => new TextureLoader(), []);
  const cachedTextures = useRef<Record<string, THREE.Texture | null>>({});

  // Функция для загрузки текстур с кэшированием

  useLayoutEffect(() => {
    const loader = new GLTFLoader();

    const loadModel = async () => {
      setIsLoading(true);
      try {
        const gltf = await new Promise<GLTF>((resolve, reject) => {
          loader.load(modelUrl, resolve, undefined, reject);
        });

        const loadedModel = gltf.scene;
        console.log('model name', gltf.scene.name);
        loadedModel.scale.set(6, 6, 6);
        loadedModel.position.set(0, -1.3, 0);

        setModel(loadedModel);
        setAnimations(gltf.animations || []);
      } catch (error) {
        console.error('Error loading model or textures: ' + error);
      } finally {
        setIsLoading(false);
      }
    };

    loadModel();

    return () => {
      setModel(null);
    };
  }, [modelUrl, textures]);

  // Функция для обновления текстур модели
  const setModelTextures = (newTextures: {
    baseColor?: string;
    emissive?: string;
    metallic?: string;
    normal?: string;
    roughness?: string;
  }) => {
    setTextures((prevTextures) => ({
      ...prevTextures,
      ...newTextures,
    }));
  };

  return (
    <ModelContext.Provider
      value={{
        isLoading,
        modelUrl,
        setModelUrl,
        activeAnimation,
        setActiveAnimation,
        setModelTextures,
        model,
        animations,
      }}
    >
      {children}
    </ModelContext.Provider>
  );
};

// Хук для использования контекста 3D модели
export const use3dModelContext = () => {
  const context = useContext(ModelContext);
  if (!context) {
    throw new Error('use3dModelContext must be used within a Model3dProvider');
  }
  return context;
};

// loadedModel.scale.set(20, 20, 20);
// loadedModel.position.set(0.1, -80, -80);
