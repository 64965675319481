import { H100, Header } from 'shared/layout';

import { PageTitle } from 'shared/typography/Typography.styled';
import { LeaderBoardTabs } from 'widget/LeaderBoardTabs';

export const LeaderBoard = () => {
  return (
    <H100>
      <Header>
        <div />
        <PageTitle>LeaderBoard</PageTitle>
        <div />
      </Header>
      <LeaderBoardTabs />
    </H100>
  );
};
