import { useAppDispatch, useAppSelector } from 'app/store/rootStore';

import { useInterval } from 'react-use';

import { EnergyIcon } from 'shared/icon';
import { Text } from 'shared/typography';
import { EnergyText, Wrapper } from './Enegry.styled';
import { CentredWrapper } from 'shared/layout';
import { Counter } from 'shared/counter';
import { useState } from 'react';
import { selectEnergy, updateEnergy } from 'entities/user';

export const Energy = () => {
  const energy = useAppSelector(selectEnergy);
  const dispatch = useAppDispatch();

  // Автообновление энергии каждые 3 секунды
  useInterval(() => {
    dispatch(updateEnergy(Math.min(energy + 1, 1000)));
  }, 3000);

  return (
    <Wrapper>
      <CentredWrapper>
        <EnergyIcon />
      </CentredWrapper>
      <EnergyText>
        <Counter
          startValueOnce={true}
          startValue={1000}
          key={'ENERGY'}
          dummyCharacters={['x']}
          value={energy}
        />
        /1000
      </EnergyText>
    </Wrapper>
  );
};
