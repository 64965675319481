import { FC } from 'react';
import { SoonProps } from './soon.types';
import { Container, Title, Text } from './Soon.styled';
import { SoonIcon } from 'shared/icon';

export const Soon: FC<SoonProps> = ({ color = '#ffffff' }) => (
  <Container>
    <SoonIcon color={color} />
    <Title>coming soon...</Title>
    <Text>
      The line to enter will <br /> clear soon
    </Text>
  </Container>
);
