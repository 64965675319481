import { colors } from 'app/colors';
import { ReferralsBalance, Username } from 'entities/user';
import { RobotScene } from 'feature/3d';
import { Level } from 'feature/level';
import { Button } from 'shared/button';
import { CentredWrapper } from 'shared/layout';
import { Text } from 'shared/typography';
import {
  ButtonWrapper,
  Stat,
  StatsWrapper,
  StatWrapper,
} from './Profile.styled';
import { UpgradeProgress } from 'widget/upgradeProgress';
import { NavLink } from 'react-router-dom';
import { router, ROUTES } from 'app/router';

const Stats = [
  { title: 'EXP', value: 450 },
  { title: 'Referals', value: 450 },
  { title: 'Activity', value: 450 },
];
export const ProfileContent = () => {
  return (
    <div>
      <CentredWrapper>
        <RobotScene width='150px' height='150px' distance={1.3} />
        <CentredWrapper>
          <Text>coming soon...</Text>
          <Text
            style={{
              fontWeight: 600,
              fontSize: 18,
              marginTop: 5,
              lineHeight: 2,
            }}
          >
            <Username />
          </Text>
          <Level progress={false} />
        </CentredWrapper>
        <StatsWrapper>
          <StatWrapper>
            EXP <Stat>XXX</Stat>
          </StatWrapper>
          <StatWrapper>
            Referrals{' '}
            <Stat>
              <ReferralsBalance />
            </Stat>
          </StatWrapper>
          <StatWrapper>
            Activity <Stat>XXX</Stat>
          </StatWrapper>
        </StatsWrapper>
        <ButtonWrapper>
          <NavLink to={ROUTES.REFERRAL}>
            <Button
              color={colors.notIntensiveColor}
              borderColor={colors.notIntensiveColor}
            >
              <Text>Referral</Text>
            </Button>
          </NavLink>
        </ButtonWrapper>
        <UpgradeProgress />
      </CentredWrapper>
    </div>
  );
};
