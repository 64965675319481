import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { differenceInMilliseconds } from 'date-fns';
import { GradientCircleWrapper, CircleContent } from './GradientTimer.styled';

import { colors } from 'app/colors';
import { GradientCircleProps } from './GradientTimer.types';
import { GradientCircle } from './GradientCircle';
import { Gradient } from 'shared/gradient/Gradient.styled';

export const GradientTimer: React.FC<GradientCircleProps> = ({
  color = colors.primaryColor,
  timer = 5,
  borderWidth = 8,
  content = null,
  isBlum = false,
  static: isStatic = false,
  status,
}) => {
  const radius = useMemo(() => 50 - borderWidth / 2, [borderWidth]);
  const circumference = useMemo(() => 2 * Math.PI * radius, [radius]);
  const gradientId = useMemo(
    () => `gradient-${Math.random().toString(36).substr(2, 9)}-${timer}`,
    [timer]
  );

  const [offset, setOffset] = useState(0);

  const updateAnimation = useCallback(
    (startTime: Date) => {
      const step = () => {
        const remainingTime = differenceInMilliseconds(new Date(), startTime);
        const progress = Math.min(remainingTime / (timer * 1000), 1);
        setOffset(circumference * progress);

        if (progress < 1) {
          requestAnimationFrame(step);
        }
      };
      step();
    },
    [timer, circumference]
  );

  useEffect(() => {
    if (isStatic) {
      setOffset(0);
      return;
    }

    const startTime = new Date();
    updateAnimation(startTime);

    return () => setOffset(0);
  }, [isStatic, updateAnimation]);

  return (
    <GradientCircleWrapper color={color} isBlum={isBlum} status={status}>
      <GradientCircle
        radius={radius}
        borderWidth={borderWidth}
        gradientId={gradientId}
        computedColor={color}
        circumference={circumference}
        isStatic={isStatic}
        offset={offset}
      />
      <CircleContent color={color}>
        <Gradient color={color}>{content}</Gradient>
      </CircleContent>
    </GradientCircleWrapper>
  );
};
