import { NavLink } from 'react-router-dom';
import { Item, Wrapper, StyledIcon } from './Menu.styled';
import { colors } from 'app/colors';
import { Button } from 'shared/button';
import { Gradient } from 'shared/gradient';
import { LotusIcon, ProfileIcon, RaitingIcon, TasksIcon } from 'shared/icon';
import { ROUTES } from 'app';
import { useToast } from 'shared/toast';
import { useAppSelector } from 'app/store/rootStore';
import { selectIsMinted } from 'entities/user';

export const Menu = () => {
  const { showToast } = useToast();
  const isMinted = useAppSelector(selectIsMinted);
  return (
    <Wrapper>
      <NavLink
        to={isMinted ? ROUTES.LAUNCH : ROUTES.MINT}
        style={{ textDecoration: 'none' }}
      >
        {({ isActive }) => (
          <Button size='l' shape='round'>
            <Gradient
              displayed={isActive}
              isFull={false}
              color={colors.primaryColor}
            >
              <Item isActive={isActive}>
                <StyledIcon as={LotusIcon} isActive={isActive} />
                HOME
              </Item>
            </Gradient>
          </Button>
        )}
      </NavLink>
      <NavLink to={ROUTES.GAME} style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button size='l' shape='round'>
            <Gradient
              displayed={isActive}
              isFull={false}
              color={colors.primaryColor}
            >
              <Item isActive={isActive}>GAME</Item>
            </Gradient>
          </Button>
        )}
      </NavLink>

      <NavLink to={ROUTES.TASKS} style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button size='l' shape='round'>
            <Gradient
              displayed={isActive}
              isFull={false}
              color={colors.primaryColor}
            >
              <Item isActive={isActive}>
                <StyledIcon as={TasksIcon} isActive={isActive} />
                TASKS
              </Item>
            </Gradient>
          </Button>
        )}
      </NavLink>
      <NavLink to={ROUTES.RATINGS} style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button size='l' shape='round'>
            <Gradient
              displayed={isActive}
              isFull={false}
              color={colors.primaryColor}
            >
              <Item isActive={isActive}>
                <StyledIcon as={RaitingIcon} isActive={isActive} />
                RATINGS
              </Item>
            </Gradient>
          </Button>
        )}
      </NavLink>

      <NavLink to={ROUTES.PROFILE} style={{ textDecoration: 'none' }}>
        {({ isActive }) => (
          <Button size='l' shape='round'>
            <Gradient
              displayed={isActive}
              isFull={false}
              color={colors.primaryColor}
            >
              <Item isActive={isActive}>
                <StyledIcon as={ProfileIcon} isActive={isActive} />
                PROFILE
              </Item>
            </Gradient>
          </Button>
        )}
      </NavLink>
    </Wrapper>
  );
};
