import { colors } from 'app/colors';
import { IconProps } from './icon.types';
import { Text } from 'shared/typography';
import { CentredWrapper } from 'shared/layout';

export const LeaderIcon: React.FC<IconProps> = ({
  color = colors.whiteColor,
  width = 24,
  height = 15,
  value,
  ...props
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <svg
      style={{ position: 'relative', left: 17 }}
      width={width}
      height={height}
      viewBox='0 0 27 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.98906 2.59837C5.56719 3.31556 5.10312 3.90618 4.5125 4.28587C3.92187 4.66556 3.28906 4.91868 2.65625 4.74993C3.12031 2.8515 4.93437 1.92337 5.98906 2.59837Z'
        fill={color}
      />
      <path
        d='M1.89849 0.363281C1.30786 1.41797 1.1813 2.47266 1.35005 3.27422C1.5188 4.07578 2.02505 4.62422 2.65786 4.75078C3.07974 2.97891 3.03755 1.03828 1.89849 0.363281Z'
        fill={color}
      />
      <path
        d='M4.8934 5.51015C4.72465 6.26953 4.38715 6.94453 3.96527 7.45078C3.5434 7.95703 2.95277 8.33671 2.31996 8.3789C2.23558 6.48047 3.66996 5.17265 4.8934 5.51015Z'
        fill={color}
      />
      <path
        d='M0.33768 4.53906C0.0845549 5.63594 0.253305 6.64844 0.67518 7.36563C1.09706 8.08281 1.68768 8.42031 2.32049 8.42031C2.23612 6.60625 1.6033 4.87656 0.33768 4.53906Z'
        fill={color}
      />
      <path
        d='M4.64156 8.54688C4.68374 9.30625 4.55718 10.0234 4.26187 10.6141C4.00874 11.2047 3.54468 11.7531 2.95406 11.9219C2.36343 10.1078 3.37593 8.54688 4.64156 8.54688Z'
        fill={color}
      />
      <path
        d='M0 8.80078C0.084375 9.89766 0.50625 10.8258 1.05469 11.3742C1.64531 11.9227 2.32031 12.1336 2.95312 11.9227C2.40469 10.2352 1.30781 8.80078 0 8.80078Z'
        fill={color}
      />
      <path
        d='M5.23209 11.459C5.48521 12.1762 5.5274 12.8934 5.40084 13.5684C5.27428 14.2434 4.97896 14.834 4.43053 15.2137C3.46021 13.5684 4.00865 11.7965 5.23209 11.459Z'
        fill={color}
      />
      <path
        d='M0.800781 12.935C1.13828 13.9475 1.81328 14.7491 2.48828 15.1288C3.20547 15.5084 3.88047 15.5085 4.42891 15.171C3.50078 13.6944 2.06641 12.5553 0.800781 12.935Z'
        fill={color}
      />
    </svg>
    <Text style={{ color: 'inherit', fontSize: 18 }}>{value}</Text>
    <svg
      style={{ position: 'relative', right: 17 }}
      width={width}
      height={height}
      viewBox='0 0 27 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.0078 2.59837C21.4297 3.31556 21.8938 3.90618 22.4844 4.28587C23.075 4.66556 23.7078 4.91868 24.3406 4.74993C23.8766 2.8515 22.0625 1.92337 21.0078 2.59837Z'
        fill={color}
      />
      <path
        d='M25.1027 0.363281C25.6934 1.41797 25.8199 2.47266 25.6512 3.27422C25.4824 4.07578 24.9762 4.62422 24.3434 4.75078C23.9215 2.97891 23.9637 1.03828 25.1027 0.363281Z'
        fill={color}
      />
      <path
        d='M22.1055 5.51015C22.2742 6.26953 22.6117 6.94453 23.0336 7.45078C23.4555 7.95703 24.0461 8.33671 24.6789 8.3789C24.7633 6.48047 23.3289 5.17265 22.1055 5.51015Z'
        fill={color}
      />
      <path
        d='M26.6625 4.53906C26.9156 5.63594 26.7469 6.64844 26.325 7.36563C25.9031 8.08281 25.3125 8.42031 24.6797 8.42031C24.7641 6.60625 25.3969 4.87656 26.6625 4.53906Z'
        fill={color}
      />
      <path
        d='M22.3595 8.54688C22.3173 9.30625 22.4438 10.0234 22.7392 10.6141C23.0345 11.2047 23.4563 11.7531 24.0892 11.9219C24.6376 10.1078 23.6251 8.54688 22.3595 8.54688Z'
        fill={color}
      />
      <path
        d='M27.0008 8.80078C26.9164 9.89766 26.4945 10.8258 25.9461 11.3742C25.3555 11.9227 24.6805 12.1336 24.0898 11.9227C24.5961 10.2352 25.693 8.80078 27.0008 8.80078Z'
        fill={color}
      />
      <path
        d='M21.7691 11.459C21.516 12.1762 21.4738 12.8934 21.6004 13.5684C21.7269 14.2434 22.0222 14.834 22.5707 15.2137C23.541 13.5684 22.9925 11.7965 21.7691 11.459Z'
        fill={color}
      />
      <path
        d='M26.1984 12.935C25.8609 13.9475 25.1859 14.7491 24.5109 15.1288C23.7937 15.5084 23.1187 15.5085 22.5703 15.171C23.4984 13.6944 24.9328 12.5553 26.1984 12.935Z'
        fill={color}
      />
    </svg>
  </div>
);
