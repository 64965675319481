import { Text } from 'shared/typography';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  width: 100%;
  text-decoration-skip-ink: none;
`;

export const EnergyText = styled(Text)`
  display: flex;
  align-items: center;
  font-size: 20px;
  letter-spacing: -0.5px;
`;
