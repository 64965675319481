import { Balance } from 'feature/balance';
import { Level } from 'feature/level';
import { H100, Header } from 'shared/layout';
import { LaunchDate } from 'widget/launchDate';

export const Launch = () => {
  return (
    <H100>
      <Header>
        <Level />
        <Balance />
      </Header>

      <LaunchDate />
    </H100>
  );
};
