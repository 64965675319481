import styled from 'styled-components';

export const Wrapper = styled.div`
  text-transform: uppercase;
  color: var(--white-color);
`;
export const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.12px;
  display: flex;
  align-items: center;
  letter-spacing: 2.5px;
  margin-bottom: 5px;
`;

export const IconWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
