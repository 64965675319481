// entities/tasks/tasks.types.ts
import { ReactNode } from 'react';

export interface Item {
  id: string;
  accountId: number;
  // type: 'enter' | 'tap' | 'referrals';
  type: string;
  name: string | ReactNode;
  link: string | null;
  isComplete: boolean;
  reward: number;
  isChecked: boolean;
  progress: null | number;
  tabType: TabType;
  target: number;
  amount: number;
  vis: number;
  pos: number;
  createdAt: string;
  updatedAt: string;
}

export enum TabType {
  Social = 'social',
  Daily = 'daily',
  Partners = 'partners',
}

export interface ItemsListProps {
  items?: Item[];
  tabType: TabType;
}

export interface TaskItemProps {
  task: Item;
  isChecked: boolean; // Добавляем isChecked для отслеживания состояния задачи
  onCheck: (taskId: number) => void; // Функция для обновления состояния
}

export interface TaskApiResponse {
  data: Item[];
}
export interface TasksState {
  mainTasks: TaskApiResponse;
  dailyTasks: TaskApiResponse;
  partnerTasks: TaskApiResponse;
  loading: boolean;
}

export interface TaskModalProps {
  task: Item;
  onClose: () => void;
  onCheck: (taskId: number) => void;
}
