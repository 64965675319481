import React from 'react';
import { Tasks } from 'entities/tasks';
import { Tabs } from 'shared/tabs';
import { TabType } from 'entities/tasks/tasks.types';
import { PageTitle } from 'shared/typography';
import { Header } from 'shared/layout';

const tabItems = [
  {
    title: 'social',
    segment: <Tasks tabType={TabType.Social} />,
    disable: false,
  },
  {
    title: 'daily',
    segment: <Tasks tabType={TabType.Daily} />,
    disable: false,
  },
  {
    title: 'partners',
    segment: <Tasks tabType={TabType.Partners} />,
    disable: false,
  },
];

export const TaskPage: React.FC = () => {
  const handleTabChange = (activeTab: string) => {};

  return (
    <>
      <Header>
        <PageTitle>Tasks</PageTitle>
      </Header>
      <Tabs
        defaultActiveTab='social'
        items={tabItems}
        onTabChange={handleTabChange}
      />
    </>
  );
};
