import { useState } from 'react';
import { useInterval } from 'react-use';
import { ProgressBar } from 'shared/progress';
import { IconWrapper, Title, Wrapper } from './Level.styled';
import { StarIcon } from 'shared/icon';
import { useAppSelector } from 'app/store/rootStore';

import { LevelProps } from '../level.types';
import { selectExperience, selectLevel } from 'entities/user';

export const Level = ({ progress = true }: LevelProps) => {
  const level = useAppSelector(selectLevel);
  const xp = useAppSelector(selectExperience);

  return (
    <Wrapper>
      <Title>
        <IconWrapper>
          <StarIcon />
        </IconWrapper>
        Level {level} {/* Отображаем текущий уровень */}
      </Title>
      {progress && (
        <>
          <ProgressBar width='144px' current={level} target={100} />
          <div>{xp} XP</div>
        </>
      )}
    </Wrapper>
  );
};
