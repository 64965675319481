import { Text } from 'shared/typography';
import styled, { keyframes } from 'styled-components';

// Создаем анимацию покачивания
const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

export const StyledText = styled(Text)`
  margin: 16px 0;
`;

// Новый компонент с анимацией
export const AnimatedItem = styled.div`
  display: inline-block; // Чтобы элемент занимал только свою ширину
  animation: ${bounce} 1.5s infinite ease-in-out; // Анимация покачивания
`;
