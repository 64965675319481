import styled, { css } from 'styled-components';

// Типы для пропсов
type Direction = 'horizontal' | 'vertical';
type Status = 'active' | 'success' | 'failed';

interface StepItemProps {
  status: Status;
}

interface GamingStepContainerProps {
  width?: string;
  height?: string;
}

interface DirectionProps {
  direction: Direction;
}

export const StepsContainer = styled.div<
  DirectionProps & { isActive: boolean }
>`
  display: flex;
  ${({ direction }) =>
    direction === 'vertical'
      ? css`
          flex-direction: column;
        `
      : css`
          flex-direction: row;
        `}
  ${({ isActive }) =>
    isActive
      ? css`
          border: 2px solid var(--primary-color);
        `
      : css`
          border: none;
        `}
  border-radius: 56px;
  padding: 6px;
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
`;

// Стиль для содержимого шагов
export const StepsContent = styled.div<DirectionProps>`
  display: flex;

  flex-direction: ${({ direction }) =>
    direction === 'vertical' ? 'column' : 'row'};
  gap: 6px;
  align-items: center;
  font-weight: 600;
  color: var(--white-color);
`;

// Стиль для элемента шага
export const StepItem = styled.div<StepItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 28px;
  font-weight: 400;
  height: 28px;
  font-size: 11px;
  position: relative;

  /* Connecting line */
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: ${({ status }) => (status === 'success' ? '-6px' : '-4px')};
    transform: translateY(-50%);
    width: ${({ status }) => (status === 'success' ? '6px' : '4px')};
    height: 2px;
    background-color: ${({ color }) => color || 'var(--primary-color)'};
    display: ${({ status }) => (status === 'failed' ? 'none' : 'block')};
  }

  &:last-child::after {
    display: none;
  }

  ${({ status }) => {
    if (status === 'success') {
      return css`
        border: none;
      `;
    }

    if (status === 'failed') {
      return css`
        border: 2px solid var(--tertiary-color);
        color: var(--tertiary-color);
      `;
    }

    return css`
      border: none;
    `;
  }}
`;

// Стиль для контейнера игрового шага
export const GamingStepContainer = styled.div<GamingStepContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 6px;
  width: 80px;
  height: 80px;
`;
