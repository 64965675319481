import { colors } from 'app/colors';
import { BalanceRow, CircleBg } from 'feature/balance';

import { Button } from 'shared/button';

import { LotusIcon } from 'shared/icon';
import { CheckmarkIcon } from 'shared/icon/Checkmark';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';
import { Text } from 'shared/typography';
import { CheckMark, IconWrapper, Wrapper } from './FakeMint.styled';
import { css } from 'styled-components';
import { Gradient } from 'shared/gradient';

export const SuccessMint = ({ onNext }: { onNext: () => void }) => {
  return (
    <Wrapper>
      <CheckMark>
        <DivDecoration width={10} height={'100%'} />
        <IconWrapper>
          <CheckmarkIcon width={50} height={50} />
        </IconWrapper>
        <DivDecoration width={10} isReverse height={'100%'} />
      </CheckMark>
      <div style={{ marginTop: 20, marginBottom: 14, textAlign: 'center' }}>
        <Text style={{ fontSize: 20, lineHeight: 2 }}>Congratulations!</Text>
        <Text style={{ color: colors.fourthColor }}>
          You have created character
        </Text>
      </div>
      <div style={{ marginBottom: 20 }}>
        <Text style={{ fontSize: 10 }}>Your prize is</Text>
        <BalanceRow>
          <span>100</span>
          <CircleBg>
            <LotusIcon color={colors.blackColor} size='15' />
          </CircleBg>
        </BalanceRow>
      </div>
      <Button
        onClick={onNext}
        decoration='basic'
        borderColor={colors.primaryColor}
        size='l'
      >
        <Gradient color={colors.primaryColor}>CONTINUE</Gradient>
      </Button>
    </Wrapper>
  );
};
