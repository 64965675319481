import { colors } from 'app/colors';
import { Button } from 'shared/button';
import { Text } from 'shared/typography';
import styled, { keyframes, css } from 'styled-components';

// Определение анимации
const disappear = keyframes`
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0);
  }
`;
const appear = keyframes`
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
`;

const streak = keyframes`
  0% {
    transform: scale(0);
  }
  20%{
    transform: scale(0.6);
  }
  50% {
    transform: scale(1);
  }
  75%{
    transform: scale(0.4);
  }
  100% {
    transform: scale(0);
  }
`;

export const GameButton = styled(Button)<{ isAnimating: boolean }>`
  ${(props) => css`
    animation: ${props.isAnimating ? disappear : appear} 0.4s forwards;
  `}
`;

export const GameWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: calc(100% - 35px);
`;

export const Streak = styled(Text)`
  color: ${colors.secondaryColor};
  font-size: 44px;
  font-weight: 700;
  text-transform: lowercase;
  text-align: center;
  text-decoration-skip-ink: none;
  letter-spacing: -1px;
  position: relative;
  height: 40px;
  animation: ${appear} 0.4s forwards;

  text-shadow: ${colors.secondaryColor + '99'} 0px 0px 30px;
`;

export const SlideWrapper = styled.div``;

export const RobotWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 57%;
  transform: translate(-50%, 50%);
`;

export const BubbleArea = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  min-height: 150px;
  width: 100%;
  color: ${colors.whiteColor};
`;

export const InfoWrapper = styled.div``;
