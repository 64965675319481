// Button.tsx
import React, { FC } from 'react';
import { Content, StyledBorderIcon, StyledButton } from './Button.styled';
import { ButtonProps } from './button.types';
import { Loader } from 'shared/loader';
import { colors } from 'app/colors';

export const Button: FC<ButtonProps> = ({
  shape = 'default',
  color,
  borderColor,
  borderSize = 1,
  children,
  isLoading = false,
  decoration,
  size = 'm',
  ...props
}) => (
  <StyledButton
    borderColor={isLoading ? colors.transperent : borderColor}
    borderSize={borderSize}
    shape={shape}
    color={isLoading ? colors.transperent : color}
    decoration={isLoading ? undefined : decoration}
    size={size}
    {...props}
  >
    <StyledBorderIcon
      decoration={isLoading ? undefined : decoration}
      color={borderColor || color}
      height={'100%'}
      isFirst
    />
    <Content>{!isLoading ? children : <Loader />}</Content>
    <StyledBorderIcon
      color={borderColor || color}
      decoration={isLoading ? undefined : decoration}
      height={'100%'}
    />
  </StyledButton>
);
