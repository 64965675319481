import React from 'react';
import { AnimatedLoader } from './Loader.styled';
import { CentredWrapper } from 'shared/layout';
import { colors } from 'app/colors';
import { LoaderProps } from './loader.types';

export const Loader: React.FC<LoaderProps> = ({
  size = '20px',
  primaryColor = colors.whiteColor,
  secondaryColor = colors.blackColor,
}) => (
  <CentredWrapper>
    <AnimatedLoader
      size={size}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
    />
  </CentredWrapper>
);
