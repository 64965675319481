import styled from 'styled-components';

export const StyledInputWrapper = styled.div<{
  isActive: boolean;
  disabled?: boolean;
  bgColor?: string;
}>`
  display: flex;
  height: 100%;
  align-items: center;
  background: ${({ bgColor }) => bgColor || 'var(--tertiary-color, #f0f0f0)'};
  padding: 13px 16px;
  transition: all 0.3s ease;
  opacity: ${({ disabled, isActive }) =>
    disabled ? 0.5 : isActive ? 1 : 0.75};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const StyledInputField = styled.input<{
  disabled?: boolean;
  color?: string;
  placeholderColor?: string;
}>`
  flex: 1;
  background: none;
  border: none;
  outline: none;
  color: ${({ color }) => color || 'var(--white-color, #000)'};
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  opacity: 1;

  &::placeholder {
    color: ${({ placeholderColor }) => placeholderColor || '#808080'};
  }

  &:disabled {
    cursor: not-allowed;

    &::placeholder {
      color: ${({ placeholderColor }) => placeholderColor || '#fff'};
    }
  }
`;

export const StyledIconContainer = styled.div<{
  isActive: boolean;
  disabled?: boolean;
}>`
  display: flex;
  margin-left: 8px;
  transition: opacity 0.3s ease;
  opacity: ${({ disabled, isActive }) =>
    disabled ? 0.5 : isActive ? 1 : 0.75};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
