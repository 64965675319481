// Timer.styled.ts
import styled from 'styled-components';

export const TimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TimerDisplay = styled.div`
  display: flex;
  gap: 5px;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.02em;
`;

export const TimerUnit = styled.span`
  color: #d8f34f;
`;

export const TimerLabel = styled.span`
  color: #848e58;
`;
