import { colors } from 'app/colors';
import { IconProps } from '../icon.types';

export const InputIcon: React.FC<IconProps> = ({
  color = colors.primaryColor,
  width = 30,
  height = 30,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M27.6263 13.2027L3.44125 1.40399C3.20884 1.29073 2.95142 1.23847 2.69324 1.25213C2.43506 1.26579 2.1846 1.34492 1.96544 1.48207C1.74627 1.61922 1.56561 1.80988 1.44045 2.03611C1.31529 2.26234 1.24975 2.51669 1.25 2.77524V2.81899C1.25012 3.02337 1.27531 3.22698 1.325 3.42524L3.645 12.7052C3.6761 12.8289 3.74426 12.94 3.84033 13.0238C3.9364 13.1076 4.0558 13.16 4.1825 13.174L14.3788 14.3077C14.5484 14.3281 14.7047 14.41 14.8181 14.5378C14.9315 14.6657 14.9941 14.8306 14.9941 15.0015C14.9941 15.1724 14.9315 15.3373 14.8181 15.4651C14.7047 15.593 14.5484 15.6748 14.3788 15.6952L4.1825 16.829C4.0558 16.843 3.9364 16.8954 3.84033 16.9792C3.74426 17.063 3.6761 17.1741 3.645 17.2977L1.325 26.5765C1.27531 26.7747 1.25012 26.9783 1.25 27.1827V27.2265C1.24996 27.4849 1.31565 27.7391 1.4409 27.9652C1.56615 28.1912 1.74683 28.3817 1.96597 28.5187C2.1851 28.6557 2.43548 28.7348 2.69356 28.7484C2.95163 28.762 3.20893 28.7097 3.44125 28.5965L27.625 16.7977C27.9618 16.6334 28.2457 16.3778 28.4442 16.06C28.6428 15.7422 28.7481 15.375 28.7481 15.0002C28.7481 14.6255 28.6428 14.2583 28.4442 13.9405C28.2457 13.6227 27.9631 13.367 27.6263 13.2027Z'
      fill={color}
    />
  </svg>
);
