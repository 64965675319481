import { colors } from 'app/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px;
  border: 1px solid ${colors.tertiaryColor};
  width: 100%;
  display: flex;
  margin: 2px 0;
  align-items: center;
`;
export const Content = styled.div`
  width: 100%;
  margin-left: 11px;
  text-align: start;
`;

export const Filter = styled.div`
  filter: blur(2px);
  width: 100%;
`;
