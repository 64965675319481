import React, { useRef, useState } from 'react';
import { Carousel } from 'shared/carousel';
import {
  BlockContainer,
  TextBlock,
  TextTitle,
  TextDescr,
  ArrowsContainer,
  Panel,
} from './Legend.styled';
import { DivDecoration } from 'shared/icon/decoration/DivDecoration';
import { BackIcon } from 'shared/icon/back/BackIcon';
import { cardsData } from './cardData';

export const Legend: React.FC = () => {
  const carouselRef = useRef<any>(null);
  const [currentSlide, setCurrentSlide] = useState(1);

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
      setCurrentSlide((prev) => (prev === cardsData.length ? 1 : prev + 1));
    }
  };

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
      setCurrentSlide((prev) => (prev === 1 ? cardsData.length : prev - 1));
    }
  };

  const handleCardClick = (index: number) => {
    setCurrentSlide(index + 1);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
    },
  };

  return (
    <BlockContainer>
      <TextBlock>
        <DivDecoration
          width={'35px'}
          height={'100%'}
          style={{ position: 'absolute', left: '0', opacity: 0.25 }}
        />
        <TextTitle>
          <h4>AI Evolution Reimagined</h4>
        </TextTitle>
        <TextDescr>
          <p>
            Empower Your Digital Journey Through Gamified AI Development and
            Personalization
          </p>
        </TextDescr>
      </TextBlock>

      <div style={{ width: '100%' }}>
        <Carousel
          ref={carouselRef}
          arrows={false}
          draggable={true}
          swipeable={true}
          responsive={responsive}
        >
          {cardsData.map((card, index) => (
            <Panel
              key={card.id}
              className={currentSlide - 1 === index ? 'active' : ''}
              onClick={() => handleCardClick(index)}
            >
              <h4>{card.title}</h4>
              <p>{card.descr}</p>
            </Panel>
          ))}
        </Carousel>
      </div>

      <ArrowsContainer>
        <button onClick={handlePrev}>
          <BackIcon />
        </button>
        <span>
          {String(currentSlide).padStart(2, '0')} /{' '}
          {String(cardsData.length).padStart(2, '0')}
        </span>
        <button onClick={handleNext}>
          <BackIcon isReverse />
        </button>
      </ArrowsContainer>
    </BlockContainer>
  );
};
