// components/LandingContent.tsx
import { Wrapper, Main } from './Landing.styled';
import { LandingHeader } from './header';
import { Legend } from './legend';
import { LandingFooter } from './footer';

export const LandingContent = () => {
  return (
    <Wrapper>
      <LandingHeader />
      <Main>
        <Legend />
      </Main>
      <LandingFooter />
    </Wrapper>
  );
};
