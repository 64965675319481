import { FC, useEffect, useState } from 'react';
import { ModalProps } from './modal.types';
import ReactDOM from 'react-dom';
import { StyledModal, ModalContent, ModalClose } from './Modal.styled';
import { CloseIcon } from 'shared/icon';

export const Modal: FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  children,
  types,
}) => {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setIsClosing(true);
    } else {
      setIsClosing(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isClosing) {
      const timer = setTimeout(() => {
        onRequestClose();
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [isClosing, onRequestClose]);

  const handleClose = () => {
    setIsClosing(true);
  };

  if (!isOpen && !isClosing) return null;

  return ReactDOM.createPortal(
    <StyledModal isClosing={isClosing}>
      <ModalContent types={types} isClosing={isClosing}>
        <ModalClose onClick={handleClose}>
          <CloseIcon />
        </ModalClose>
        {children}
      </ModalContent>
    </StyledModal>,
    document.body
  );
};
