import { BatteryIcon, IntelligenceIcon, PersonalityIcon } from 'shared/icon';
import { Content, Filter, Wrapper } from './UpgradeProgress.styled';
import { Button } from 'shared/button';
import { ProgressBar } from 'shared/progress';
import { Text } from 'shared/typography';
import { colors } from 'app/colors';

const UPGRADES = [
  { title: 'Personality', icon: <PersonalityIcon />, progress: 10 },
  { title: 'Intelligence', icon: <IntelligenceIcon />, progress: 30 },
  { title: 'Battery ', icon: <BatteryIcon />, progress: 40 },
];

export const UpgradeProgress = () => {
  return (
    <Filter>
      {UPGRADES.map(({ title, icon, progress }) => {
        return (
          <Wrapper>
            <Button
              borderSize={0}
              size='xs'
              decoration='basic'
              borderColor={colors.whiteColor}
            >
              {icon}
            </Button>
            <Content>
              <Text>{title}</Text>
              <ProgressBar current={progress} target={100} />
            </Content>
          </Wrapper>
        );
      })}
    </Filter>
  );
};
