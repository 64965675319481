import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledBackButton } from './Navback.styled';
import { BackIcon } from 'shared/icon/back/BackIcon';

export const Navback: React.FC = () => {
  const navigate = useNavigate();

  return (
    <StyledBackButton onClick={() => navigate(-1)}>
      <BackIcon />
    </StyledBackButton>
  );
};
